import React from 'react';
import {MuiThemeProvider, TextField, withStyles, Avatar, Tooltip, Button, Popper, List, ListItem, ListItemText, Fade, ClickAwayListener, Input, MenuItem, Menu} from '@material-ui/core';
import {LocalLibrary, Brightness2, CloseRounded, ClearAll, Timeline, AccountTree} from '@material-ui/icons';
import { ReactSortable } from 'react-sortablejs';

import {theme} from '../user_interface/assets/constants'
import {styles} from '../user_interface/assets/playerInfoUI'
import EE from '../../../api/eventemitter'
import socket from '../../../api/socket'
import {toast} from 'react-toastify'
import {getUserId, isLeader} from '../../../utility/function'
import 'react-toastify/dist/ReactToastify.css'
import '../user_interface/assets/gamePassButton.css'
import '../user_interface/assets/snackbarUI.css'
import '../user_interface/assets/playerInfoTyping.css'
toast.configure() 

class GamePlayerInfo extends React.Component{
    constructor(props){
        super(props)
        this.refsArray = [];
        this.state = {
            playerInfo : false,
            linearTurn: false,
            turnMode:false,
            anchorEl:null,
            message: null,
            playerStatus: new Map(),
            editScore:[],
            anchorAvatar: null,
            targetAvatar: null,

            playerList: this.props.playerList || []
        };

        this.idleTimer = new Date().getTime()

        this.eventArr = {
            'pointermove': "active", 
            'keydown' : "typing", 
            'wheel' : "active",
            'keyup': "active"
        }
    }

    componentDidMount(){
        let playerStatus = new Map()
        let editScore = []

        for(let i = 0; i < this.props.playerList.length; i++){
            let player = this.props.playerList[i]
            
            playerStatus.set(player.uid, "active")
            editScore[i] = false

            this.setState({
                playerStatus: playerStatus,
                editScore: editScore
            })

        }

        //USER STATUS
        for(let i = 0; i < Object.keys(this.eventArr).length; i++){
            let eventName = Object.keys(this.eventArr)[i]

            document.getElementById('root').addEventListener(eventName, event => {
                this.idleTimer = new Date().getTime()

                const playerStatus = this.state.playerStatus

                if(playerStatus.get(getUserId()) !== this.eventArr[eventName]){
                    if(eventName === 'keyup'){
                        
                        this.keyupTimer = setTimeout(() =>{
                            this.setState({
                                playerStatus: playerStatus.set(getUserId(), this.eventArr[eventName])
                            }, () =>{
                                const status = this.state.playerStatus.get(getUserId())
                                socket.emit("Sync Player Status", {uID: getUserId(), status: status})
                            })
                        }, 600)
                    }
                    else{
                        eventName === 'keydown' && this.keyupTimer && clearTimeout(this.keyupTimer)
                        
                        this.setState({
                            playerStatus: playerStatus.set(getUserId(), this.eventArr[eventName])
                        }, () =>{
                            const status = this.state.playerStatus.get(getUserId())
                            socket.emit("Sync Player Status", {uID: getUserId(), status: status})
                        })
                    }
                }
                
                if(eventName === "wheel"){
                    //Disable control+scrollwheel zoom on browsers
                    event.ctrlKey && event.preventDefault()
                }
            }, true)
        }


        setInterval(() =>{
            //Date getTime returns a unix time in milliseconds since epoch
            //Get the status of current of user
            if((this.state.playerStatus.get(getUserId()) !== 'idle' && (new Date().getTime() - this.idleTimer) / 1000) > 300){
                let status = "idle"
                const playerStatus = this.state.playerStatus
                this.setState({
                    playerStatus: playerStatus.set(getUserId(), status)
                }, () =>{
                    const status = this.state.playerStatus.get(getUserId())
                    socket.emit("Sync Player Status", {uID: getUserId(), status: status})
                })
            }
        }, 300)

        socket.on("Sync Player Status", (data)=>{
            const {uID, status} = data
            const playerStatus = this.state.playerStatus
            this.setState({
                playerStatus: playerStatus.set(uID, status)
            })
        })
    }

    componentWillMount() {
            //USER STATUS
        for(let i = 0; i < Object.keys(this.eventArr).length; i++){
            let eventName = Object.keys(this.eventArr)[i]

            document.getElementById('root').removeEventListener(eventName, event => {
                this.idleTimer = new Date().getTime()

                const playerStatus = this.state.playerStatus

                if(playerStatus.get(getUserId()) !== this.eventArr[eventName]){
                    if(eventName === 'keyup'){
                        
                        this.keyupTimer = setTimeout(() =>{
                            this.setState({
                                playerStatus: playerStatus.set(getUserId(), this.eventArr[eventName])
                            }, () =>{
                                const status = this.state.playerStatus.get(getUserId())
                                socket.emit("Sync Player Status", {uID: getUserId(), status: status})
                            })
                        }, 600)
                    }
                    else{
                        eventName === 'keydown' && this.keyupTimer && clearTimeout(this.keyupTimer)
                        
                        this.setState({
                            playerStatus: playerStatus.set(getUserId(), this.eventArr[eventName])
                        }, () =>{
                            const status = this.state.playerStatus.get(getUserId())
                            socket.emit("Sync Player Status", {uID: getUserId(), status: status})
                        })
                    }
                }
                
                if(eventName === "wheel"){
                    //Disable control+scrollwheel zoom on browsers
                    event.ctrlKey && event.preventDefault()
                }
            }, true)
        }
    }

    render(){
        const {classes, passing, playerList, playerTurn, passAction} = this.props;
        const {anchorEl} = this.state
        // console.log(this.props.isLeader)

        return (
            <MuiThemeProvider theme={theme}>
                <div className={classes.playerInfoHolder}>
                    <div className={classes.playerInfoWrapper}>
                        {this.props.isSpectate && 
                            <div style={{marginBottom:"1em",fontSize:'16px',background:'#c4c4c4',padding:'.75em',borderRadius:"10px",display:"flex",width:"fit-content"}}>
                                You are a &nbsp; <strong>Spectator</strong>&nbsp;<span onClick={()=>{
                                    window.open('https://jamitio.notion.site/Spectator-10a1953008d0806bbb55ce97334ba900',"_blank");}} 
                                    style={{marginLeft:"2px",cursor:"pointer"}}class="material-symbols-outlined">info</span>
                            </div>
                        }
                        {this.createAvatar(playerList, classes)}
                        <ClickAwayListener onClickAway={this.closePassMenu}>
                            <div className={classes.actionBtnWrapper}>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className={!passing?.canPass ? classes.passBtn : "glow"}
                                    style={{color:"white", marginBottom:"100px", padding:"0.2rem 3.3rem 0.2rem 3.3rem", outline:"none"}}
                                    disabled={!passing?.canPass ?? true}
                                    onClick={(e)=>this.openPassMenu(e)}
                                >
                                    <b>NEXT</b>
                                </Button>
                                <Popper
                                    id={Boolean(anchorEl) ? "pass-popper" : undefined}
                                    open = {Boolean(anchorEl)}
                                    anchorEl={anchorEl}
                                    placement={'top-start'}
                                    transition
                                >
                                {({TransitionProps}) =>(
                                    <Fade {...TransitionProps}>
                                        <List
                                            style={{
                                                background: 'white',
                                                width:'8.6rem',
                                                paddingTop: "0rem",
                                                paddingBottom: "0rem"
                                            }}
                                        >
                                            {passing?.canPass && this.actionName(playerTurn, playerList, passAction, classes)}
                                        </List>
                                    </Fade>
                                )}
                                </Popper>
                            </div>
                        </ClickAwayListener>
                    </div>
                </div>
            </MuiThemeProvider>
          );
    }

    actionName(playerTurn, playerList, passAction, classes){
        //Note to Lis: you can change the pass action according to the if else condition
        // passAction = {
        //     shiftAction: "Choose Player",
        //     shiftTo: "Phase B"
        // } 
        let shiftActionType = passAction.shiftAction

        if(shiftActionType === "Chosen Player"){
            let listArr = []

            for(let i = 0; i < playerList.length; i++){
                let player = playerList[i]
                let playerName = player.username

                playerName = playerName[0].toUpperCase() + playerName.slice(1)

                listArr.push(
                    <>
                        <ListItem
                            key={`${player.uid}`}
                            className={classes.menuItemHover}
                            disabled={playerTurn === i}
                            onClick={() =>{
                                if(playerTurn !== i){
                                    EE.emit("Game Change Player YAML", {playerIndex: i})
                                    this.closePassMenu()
                                }
                            }}
                        >
                            <ListItemText className={classes.menuItemText}>
                                <b>{playerName}</b>
                            </ListItemText>
                        </ListItem>
                    </>
                )
            }  
            
            return listArr
        }
        else{
            return(this.singleOptionMenu(playerTurn, playerList, passAction, classes))
        }
    }

    singleOptionMenu = (playerTurn, playerList, passAction, classes) =>{
        let shiftActionType = passAction.shiftAction
        let shiftAction = null
        
        if(passAction?.shiftAction){
            if(shiftActionType === "Next Step"){
                shiftAction = 
                    <> 
                        <ClearAll className={classes.menuIcon}/> 
                        <ListItemText className={classes.menuItemText}>
                            {passAction.shiftTo}
                        </ListItemText> 
                    </>
            }
            else if(shiftActionType === "Next Player"){
                let nextPlayerName = playerTurn + 1 < playerList.length ?
                playerList[playerTurn + 1].username :
                playerList[0].username
    
                nextPlayerName = nextPlayerName[0].toUpperCase() + nextPlayerName.slice(1)
    
                shiftAction = 
                    <ListItemText className={classes.menuItemText}>
                        <b>{nextPlayerName}</b> (Next Player) 
                    </ListItemText>
            }
            else if(shiftActionType === "Next Phase"){
                shiftAction = 
                    <> 
                        <AccountTree className={classes.menuIcon}/> 
                        <ListItemText className={classes.menuItemText}>
                            {passAction.shiftTo}
                        </ListItemText> 
                    </>
            }
            else if(shiftActionType === "Next Turn Structure"){
                shiftAction = 
                    <> 
                        <Timeline className={classes.menuIcon}/> 
                        <ListItemText className={classes.menuItemText}>
                            {passAction.shiftTo}
                        </ListItemText> 
                    </>
            }
        }
        else if(passAction?.event){
            shiftAction = 
                <ListItemText className={classes.menuItemText}>
                    <b>Start Event</b>
                </ListItemText>
        }

        return(
            <ListItem
                className={classes.menuItemHover}
                onClick={()=>{
                    this.props.checkAction("Pass Turn")
                    this.closePassMenu()
                }}
            >
                {shiftAction}
            </ListItem>
        )
    }

    // setMessage = (message) =>{
    //     //Playernames is an array that in which
    //     //0th index is always the interuptor/action initiator
    //     //1st index is the person receiving the action
    //     // if(message.playerNames != null){
    //     const {playerNames, yamlMessage} = message

    //     let modifiedMessage = yamlMessage
    //     let rex = null

    //     if(message.playerNames === null){
    //         return modifiedMessage
    //     }
    //     else{
    //         for(let i = 0; i < playerNames.length; i++){
    //             rex = new RegExp("(\\[" + i + "\\])", "g")
    //             modifiedMessage = modifiedMessage.replace(rex, playerNames[i])
    //         }

    //         return modifiedMessage
    //     }
    // }

    // message() {
    //     var msg = this.setMessage(this.props.message)
    //     if(this.state.message !== msg){
    //         this.setState({ 
    //             message: msg
    //         }, ()=>{
    //             let modMsg = this.state.message.split("<br/>")
    //             toast(
    //                 ({ closeToast }) => 
    //                     <Grid container direction="row" justify="flex-start" alignItems="center">
    //                         <Grid item xs={2}>
    //                             <Avatar style={{float:"left", marginRight:"10px", width:"40px", height:"40px", border:"2px solid white"}} src={require('../../../element/abu.png')}></Avatar> 
    //                         </Grid>
    //                         <Grid item xs={10}>
    //                         <p style={{fontSize:"14px", color:"#FFF", margin:"0"}}>
    //                             <span style={{fontSize:"14px", color:"#F2C94C"}}><b>{modMsg[0]}</b></span><br/>
    //                             {modMsg[1]}
    //                         </p>
    //                         </Grid>        
                            
    //                     </Grid>
    //                 , 
    //                 {
    //                     position: "bottom-left",
    //                     autoClose: 20000,
    //                     hideProgressBar: true,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     closeButton: false,
    //                 })

    //         }) 
    //     }
    // }

    createAvatar = (playerList, classes) =>{
        const {setScorePlayerList} = this.props;

        // console.log(this.props.playerTurn, playerList)

        let avatar = []
        for(let i=0; i < playerList.length; i++){
            avatar.push(
                <div onClick={(e)=>{
                    if(this.props.isLeader){
                        
                        if(this.state.anchorAvatar){
                            this.setState({
                                anchorAvatar: null,
                                targetAvatar: null
                            })
                        }else{
                            this.setState({
                                anchorAvatar: e.currentTarget,
                                targetAvatar: i
                            })
                        }
                    }
                }} 
                    key={`info-avatar-${i}`} style={{display:"inline-block", position:"relative", margin:"0 10px"}}>
                    {/* Player info card */}
                    {/* {this.state.playerInfo ?
                        <CSSTransition>
                            <div id={`${i}-player`} 
                                className={classes.playerInfo} 
                                style={
                                    this.state.playerInfo ? {opacity:"1" , marginTop:"-170px"} :  {opacity:"0" , marginTop:"-120px"}
                                }
                            >
                                <Tooltip title="Close" placement="top">
                                    <CloseRounded style={{cursor:"pointer"}} onClick={()=>this.togglePlayerInfo()} className={classes.closeIcon}/>
                                </Tooltip>
                                <Avatar style={{margin:"auto",width:"25px", height:"25px"}} className={i===this.props.playerTurn && classes.active}>
                                    <LocalLibrary style={{fontSize:"15px"}} className={classes.roleIcon}/>
                                </Avatar>
                                <div>
                                    <b className={classes.cardRole}>{playerList[i].role}</b><br/>
                                    <p className={classes.cardName}>{playerList[i].username}</p>
                                </div>
                                <p className={classes.cardPermission}>
                                <span role="img" aria-label="Post Story" className={`${classes.cardPermissionList} ${!playerList[i]["permissions"]["cardConfig"]["edittable"] && classes.fade}`}>
                                    📝 Post Story
                                </span><br/>
                                <span role="img" aria-label="Interrupt" className={`${classes.cardPermissionList} ${i===this.props.playerTurn && classes.fade}`}>
                                    👆🏻Interrupt
                                </span><br/>
                                <span role="img" aria-label="Pass Turn" className={`${classes.cardPermissionList} ${i!==this.props.playerTurn && classes.fade}`}>
                                    ⏭ Pass turn
                                </span>
                                </p>
                            </div>
                        </CSSTransition>
                        :
                        null
                    } */}
                    <div id={`${i}-avatar`} className={`${classes.avatarBg} ${i===this.props.playerTurn ? classes.activeBg : ""}`}>
                        <div className={classes.avatarWrapper}>
                            <Avatar
                                className={classes.avatar}
                                style={{background: playerList[i].usercolor}}
                                //onClick={()=>this.togglePlayerInfo()}
                            >
                                {playerList[i].username.charAt(0).toUpperCase()}
                            </Avatar>
                        </div>
                        {/* <Tooltip title={`${i===this.props.playerTurn ? "Active" : "Passive"}`}placement="top">
                        <div className={`${classes.role} ${i===this.props.playerTurn ? classes.active : ""}`}>
                            <LocalLibrary className={classes.roleIcon}/>
                        </div>
                        </Tooltip> */}

                        {/* SCORE */}
                        <Tooltip title="Score" placement="top">
                            {/* <p className={classes.score}>{playerList[i].score}</p> */}
                            <div className={classes.score}>
                                <ClickAwayListener
                                    onClickAway={() =>{
                                            let temp = this.state.editScore
                                            temp[i] = false
                                            this.setState({
                                                editScore: temp
                                            })
                                        }
                                    }
                                >
                                    <Input
                                    onKeyDown={(e)=>{if(e.key === "Enter"){e.preventDefault(); this.setState({editScore: false})}}}
                                    className="avatar-score-nodrag"
                                    classes={{input: classes.input}}
                                    style={{padding:"0", background:this.state.editScore[i] ? "yellow" : "transparent",fontSize:"12px",fontWeight:"bold", color:playerList[i].score == 0 ? "orange" : "#4f4f4f", textAlign:"center"}}
                                    inputProps={{style:{textAlign:"center"}}}
                                    //InputProps={{disableUnderline: true, style:{fontSize:"12px", color:"white"}}}
                                    value={playerList[i].score}
                                    readOnly={!this.state.editScore[i]}
                                    onClick={(e)=>{
                                        e.stopPropagation()
                                        if(this.props.isLeader){
                                            let temp = this.state.editScore
                                            temp[i] = true
                                            
                                            this.setState({
                                                editScore: temp
                                            })
                                        }
                                    }}
                                    // autoFocus={this.state.editScore[i]}
                                    onInput = {(e) =>{
                                        let num = Number(e.target.value)
                                        if(Math.floor(num) === num && num){
                                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)
                                        }
                                        else{
                                            e.target.value = 0
                                        }
                                       
                                    }}
                                    onChange = {(e)=>{setScorePlayerList(playerList[i].uid, e.target.value)}}
                                    />
                                </ClickAwayListener>
                            </div>
                           
                        </Tooltip>
                        
                        {/* STATUS */}
                        <div className={`${classes.role}`}>
                            {this.getStatus(playerList[i].uid)}
                        </div>
                    <p className={classes.name}>{playerList[i].username}</p>
                    </div>
                    <Menu
                        id="player-avatar-menu"
                        anchorEl={this.state.anchorAvatar}
                        open={Boolean(this.state.anchorAvatar)}
                        onClose={()=>{this.setState({anchorAvatar:null})}}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}    
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        PaperProps={{
                            style:{
                                marginLeft: 50,
                            }
                        }}

                    >
                        <MenuItem onClick={()=>{
                            // console.log(this.state.targetAvatar)
                            this.setState({
                                anchorAvatar:null
                            })
                            EE.emit("Host Target Change Player",{target: this.state.targetAvatar})
                        }}
                        >
                                <p>Set as in turn</p>
                        </MenuItem>
                    </Menu>
                </div>
            )
        }
       // return avatar
        return (
            <ReactSortable
                disabled={!this.props.isLeader}
                style={{display:"inline"}}
                animation = {150}
                list = {this.state.playerList}
                filter="input"
                preventOnFilter={false}
                setList={newState => {
                    //EE.emit("Host Sort Player",{newParticipants: newState})
                    this.setState({
                        playerList: newState
                    })
                }}
                onEnd={(event)=>{
                    let {oldIndex, newIndex} = event;
                    //console.log(oldIndex,newIndex)

                    if(oldIndex !== newIndex){
                        let npt = null

                        if(oldIndex === this.props.playerTurn){
                            npt = newIndex
                        }

                        if((oldIndex !== this.props.playerTurn) && (newIndex === this.props.playerTurn) ){
                            npt = oldIndex
                        }
                        //console.log(npt)
    
                        EE.emit("Host Sort Player",{newParticipants: this.state.playerList, newPlayerTurn: npt})
                    }


                }}
            >
                {avatar}
            </ReactSortable>
        )
        
    }
    
    getStatus=(uid)=>{
        const {classes} = this.props
        const {playerStatus} = this.state
       
        if(playerStatus.get(uid) === "typing"){
            return (
                <Tooltip title={"Typing"}>
                    <div className={`${classes.status}`} style={{top:"55px", right:"9px"}}>
                        <div className={`${classes.statusTyping}`}>
                            <p className="loading" style={{color:"white", width:"18px", margin:"-26.5px 0 -9px 0", fontSize:"25px"}}></p>
                        </div>
                    </div>
                </Tooltip>
            )
        }
        else if(playerStatus.get(uid) === "idle"){
            return (
                <Tooltip title={"Idle"}>
                    <div className={`${classes.status}`}>
                        <div style={{marginTop:"-2px"}}>
                            <Brightness2 style={{fontSize:"14px", color:"#FFAA0D", transform:"rotate(40deg)"}}/>
                        </div>
                    </div>
                </Tooltip>
                
            )
        }
        return (
            <Tooltip title={"Active"}>
                <div className={`${classes.status}`}>
                    <div style={{background:"#2CB67D", width:"15px", height:"15px", borderRadius:"20px"}}>
                    </div>
                </div>
            </Tooltip>
            
        )
    }
    openPassMenu=(e)=>{
        this.setState({
            anchorEl:e.currentTarget
        })
    }

    closePassMenu=()=>{
        this.setState({
            anchorEl:null
        })
    }

    togglePlayerInfo = () =>{
        this.setState(prev =>({
            playerInfo: !prev.playerInfo,
        }))
    }
}

export default withStyles(styles)(GamePlayerInfo);
//Node modules
import React from 'react';
import {Grid, Divider, IconButton, Button, Card,
        Link, ListSubheader,withStyles , Input, FormControl, FormControlLabel, Radio, Tooltip, Avatar,
        RadioGroup, Popper, ClickAwayListener, Fade, TextField, Modal, Backdrop, Menu, MenuItem, Switch} from '@material-ui/core';
import { withApollo } from 'react-apollo'
import {DnsOutlined, 
        ExpandMore,
        Eco,
        MoreVert,
        ArrowUpwardRounded,
        ArrowDownwardRounded,
        DeleteOutlined,
        CropOriginal,
        SwapHorizRounded,
        AddPhotoAlternateOutlined,
        SettingsOutlined,  
        AccountTreeOutlined, 
        Timeline, 
        TransferWithinAStation, 
        PersonOutline, 
        ClearAll, 
        VpnKeyOutlined, 
        FlashOnRounded, 
        CloseRounded,
        AddCircleOutline,
        Build,
        Create,
        ControlCamera,
        LocalOfferOutlined,
        Apps,
        PublishRounded,
        EjectRounded,
        Toc,
        FilterFrames,
        Widgets} from '@material-ui/icons';
import { withRouter } from 'react-router-dom'
import {Redirect} from 'react-router-dom'
import LazyLoad from 'react-lazyload';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import config from '../../../config/backend'
import Dropzone from 'react-dropzone';
import request from 'superagent';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
//Other modules
import {styles} from './assets/jamBuilderUI'
import JamBuilderLoader from './assets/jamBuilderLoader'
import './assets/jamBuilderUI.css'
import {GET_JAMBUILDER_BY_ID,GET_USER_DECKS} from '../../../api/graphql'
import {JAMBUILDER_INTERPRETER,UPDATE_JAMBUILDER_IMAGE, JAMBUILDER_CHANGE_DECK, GAME_JAMBUILDER_SETUP, GAME_UPDATE_SPECTATORMODE} from '../../../api/graphql-mutation'
import GameTopPanel from './gameTopPanel'
import NodeSettings from './jamNodeSettings'
import session from '../../../model/session'
import Loader from '../../../component/loader'
import CardLoader from '../user_interface/assets/cardLoader'
import {getUserId, isEmpty, replaceWhiteSpace} from '../../../utility/function'
import {JamBuilderUtil, defaultTemplate, addRowTemplate, storyGameTemplate, freeStyleTemplate} from './helper/jamBuilderHelper'
import {userAUTH} from '../../../config/authetication'
        
toast.configure() 
class JamBuilder extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            user: null,
            userCheck: false,
            realGraph: null,
            realGraphCheck: false,
            selectedNodeId: null,
            settingsIsOpen: false,
            selectedNode: null,
            graph: null,
            graphName: null,
            errorNodes: null,
            warningNodes: null,
            selectedFile: null,

            loading: false,

            //Jambuilder related data
            data: null,

            //Settings state
            description: "Everything you need to have to build story game. This template consists of 5 set of card decks from Character, Place, Events and 1 blank card for players to customize their own story",
            rulebook:"💬 Host writes criteria by attaching card to their posts\n\n⏱ Timer can be set manually\n\n💡 Share your idea during your turn\n\n🏆 Host will choose the best idea",

            privacy: "Private",
            playerMode: "Quick",

            spectateMode: false,
            notifyOnTurn: false,

            //Current page nav
            //page:"Deck Preview",
            page: "Deck Preview",
            //Popper settings
            anchorEl: null,

            //Rulebook settings
            canEdit: false,

            //Deck Selection settings
            deckSelection: "predefined",

            //Room title settings
            canEditTitle: false,

            src: "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg",
            crop: { aspect: 16 / 9 },

            templateImage: null,
            imageCompleted: 0,

            tempSwitchDeckMenu: null, // temporary switch deck menu

            //Filter Settings
            filterName: "Category",
            filterType: 0,
            filterAnchorEl: null,

            //Deck Settings
            deckAnchorEl: null,

            displayModal:false,

            gameLink: null,


        }

        this.userDecksById = null
    }

    componentDidMount(){
        this.getSession();
        this.getGraph();

        window.addEventListener('keydown',(event) => {
            const {selectedNodeId, selectedNode, graph} = this.state
            if(selectedNodeId && selectedNode){
                let key = event.key
                const node = graph[selectedNode]

                if(key === "ArrowUp"){
                    this.moveNodeUp(node, selectedNode)
                }
                if(key === "ArrowDown"){
                    this.moveNodeDown(node, selectedNode)
                }

            }
        });
    }

    render(){
        const {classes} = this.props
        let jbid = JamBuilderUtil.getJBID();
        const popoverId = this.state.anchorEl ? 'help-popover' : undefined;
    
        if(jbid && this.state.user && this.state.realGraphCheck){
            return(
                <>
                    <GameTopPanel/>
                    {/* TOP PANEL */} 
                    <div className={classes.leftPanel}>
                        <Grid container justify="center" alignItems="center">
                            <Grid item xs={3}>
                                <div style ={{float: 'left',}}>
                                    <Tooltip title={"Deck Preview"}>
                                        <IconButton className={this.state.page!=="Deck Preview" ? classes.leftPanelBtn : classes.leftPanelBtnSelected} onClick={() => this.setState({page:"Deck Preview"})}>
                                            <FilterFrames/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div style={{ float: 'left'}}>
                                    <Tooltip title={"Automation"}>
                                        <IconButton className={this.state.page!=="Automation" ? classes.leftPanelBtn : classes.leftPanelBtnSelected} onClick={() => this.setState({page:"Automation"})}>
                                            <DnsOutlined/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div style={{ float: 'left', marginRight:"10px"}}>
                                    <Tooltip title={"Canvas Setup"}>
                                        <IconButton className={this.state.page!=="Canvas Setup" ? classes.leftPanelBtn : classes.leftPanelBtnSelected} onClick={() => {this.setState({page:"Canvas"}); window.location.href=`${window.location.origin}/game/${this.state.gameLink}` }}>
                                            <Widgets/>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <p style={{fontSize:"14px", marginTop:"12px", marginLeft:"10px", color:"#4F4F4F"}}>
                                    {this.state.page}
                                </p>
                            </Grid>
                            <Grid item xs={6} style={{textAlign:"center"}}>
                                <ClickAwayListener
                                    onClickAway={() =>
                                        this.setState({canEditTitle: false}, () =>{
                                            JamBuilderUtil.updateName(jbid, this.state.data.name)
                                        })
                                    }
                                >
                                    <Input
                                        style={{
                                            color: "white",
                                            fontSize:"14px", 
                                            fontWeight:"bold",
                                            background: !this.state.canEditTitle ? "transparent" : "#9DACC9",
                                            width: this.state.data.name.length * 10
                                        }}
                                        inputProps={{style:{textAlign:"center"}, maxLength:17}}
                                        disableUnderline
                                        name="Template Title"
                                        value={this.state.data.name}
                                        readOnly={!this.state.canEditTitle}
                                        onChange={event => this.handleTitleChange(event)}
                                        onClick={() => {
                                            this.setState({canEditTitle: true})
                                        }}
                                    />
                                </ClickAwayListener>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container  justify="flex-end" alignItems="center">
                                    <ClickAwayListener onClickAway={this.closePopper}>
                                        <div style={{float: 'left',}}>
                                            <Popper
                                                id={popoverId}
                                                open={this.state.anchorEl ? true : false}
                                                anchorEl={this.state.anchorEl} 
                                                placement="bottom-end"
                                                transition
                                            >
                                                {({ TransitionProps }) => (
                                                <Fade {...TransitionProps}>
                                                    <div style={{background:"#FAF9FA", color:"#4F4F4F", padding:"2rem", borderRadius:"5px", boxShadow:"0px 4px 4px #c4c4c4"}}>
                                                        <EjectRounded style={{position:"absolute", color:"#FAF9FA", fontSize:"40px", top:"-20px", right:"5px"}} />
                                                        <div style={{position:"absolute", top:"2rem", right:"2rem",color:"#4173D6", cursor:"pointer"}}>
                                                            <Link onClick={() =>this.setState({page:"Settings"})}>
                                                                Edit
                                                            </Link>
                                                        </div>
                                                        <p style={{color:"#4F4F4F", fontWeight:"bold", fontSize:"14px", margin:"0"}}>
                                                            Rulebook<br/>
                                                            <span style={{color:"#A7A7A7", fontWeight:"normal", fontSize:"12px"}}>This the jam’s How To Play description</span>
                                                        </p>
                                                        <br/>

                                                        <TextField
                                                            readOnly={true}
                                                            name="rulebook"
                                                            style={{width:"100%", background:"none", border:"none"}}
                                                            InputProps={{disableUnderline: true, style:{color: "#4F4F4F", fontSize:"12px", fontFamily:"Arial"}}}
                                                            onChange={event => this.handleChange(event)}
                                                            multiline={true}
                                                            value={this.state.rulebook}
                                                        />
                                                    </div>
                                                </Fade>
                                                )}
                                            </Popper>
                                            <Tooltip title={"Rulebook"}>
                                                <IconButton className={this.state.anchorEl ? classes.leftPanelBtnSelected : classes.leftPanelBtn} onClick={(e) => this.openPopper(e)}>
                                                    <Toc/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </ClickAwayListener>
                                    <div style ={{float: 'left',}}>
                                        <Tooltip title={"Settings"}>
                                            <IconButton className={this.state.page!=="Settings" ? classes.leftPanelBtn : classes.leftPanelBtnSelected} onClick={() => this.setState({page:"Settings"})}>
                                                <SettingsOutlined/>
                                            </IconButton>
                                        </Tooltip>
                                    </div> 
                                    <Button style={{ background:"orange", color:"white", marginLeft:"5px",outline:"none"}}
                                        onClick={()=>{
                                            //check if there's any settings in event script that isn't filled 
                                            const emptyState = this.checkEmptyEvent()
                                            // console.log(this.state.graph)
                                            if(!emptyState){
                                                this.setState({
                                                    loading: true
                                                }, ()=>{
                                                    this.interpretGraph()
                                                    
                                                });
                                            }
                                            else{
                                                this.nodeError("Ooops!", "You’ve missed a setting under an event script...")
                                            }
                                            
                                        }}
                                        startIcon={<PublishRounded/>}
                                    >
                                        PUBLISH TEMPLATE
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    {/* BUILDER MAIN BODY */}
                    <div className={classes.main}>
                        {this.state.loading ?         
                            <div style={{
                                zIndex: "3",
                                position: "fixed",
                                width: '100%', 
                                height: '100%', 
                                backgroundColor: 'black', 
                                opacity:'0.5'
                            }}>
                                <JamBuilderLoader />
                            </div>
                            :
                            null
                        }


                        <ToastContainer
                            position="bottom-left"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                        {this.currentPage(classes)}
                    </div>
                    <div>
                        <Modal
                            className={classes.modal}
                            open={this.state.displayModal}
                            onClose={this.handleCloseModal}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                            timeout: 500,
                            }}
                        >
                            <Fade in={this.state.displayModal}>
                                <div className={classes.modalInfo}>
                                    {
                                        this.state.infoType === 1 &&
                                        <>
                                            <p className={classes.modalHeader}>Template published!</p>
                                            <p>You’ve successfully published <b>{this.state.data.name}</b> template. If it's public, users can now access it on Templates by Community</p>
                                            <Button className={classes.modalBtn1} onClick={this.handleCloseModal}>KEEP EDITING</Button> 
                                            <Button className={classes.modalBtn2} 
                                                onClick={() => {
                                                    return (
                                                        this.props.history.push({
                                                            pathname:`/`,
                                                            state: {
                                                                redirect: window.location.pathname,
                                                                jbID: jbid,
                                                                openPreview: true,
                                                            }
                                                        })
                                                    )}}
                                            >PREVIEW TEMPLATE</Button>
                                        </>
                                    }

                                    {
                                        this.state.infoType === 2 &&
                                        <>
                                            <ReactCrop src={this.state.src} crop={this.state.crop} onChange={newCrop => this.setState({crop: newCrop})}/>;
                                        </>
                                    }
                                    
                                </div>
                            </Fade>
                        </Modal>
                    </div>
                </>
                
            )

        }else{
            if(this.state.user == null && this.state.userCheck === true){
                return(
                  <Redirect to={{
                    pathname:`/login`,
                    state: {redirect: window.location.pathname}
                  }} />
                )
            }else{
      
                return(
                    <Grid container justify="center" alignItems="center" style={{position:"fixed", width:"100%", height:"80%", overflow:"hidden"}}>
                        <Grid item>
                            <CardLoader/>
                        </Grid>
                    </Grid>
                )
      
            }
        }
        
    }

    handleOpenModal = (type) =>{
        if(type===1){
            this.setState({
                displayModal: true,
                infoType: type
            })
        }
        if(type===2){
            this.setState({
                src: document.getElementById("imageUpload").files[0].value,
                displayModal: true,
                infoType: type
            })
        }
    }

    handleCloseModal = () =>{
        this.setState({
            displayModal: false,
        })
    }

    filterCategory = (arr) => {
        let result = []
        for(var i = 0; i<arr.length;i++){
            result.push(arr[i].name)
        }
        return result
    }

    //PAGES
    currentPage = (classes) =>{
        if(this.state.page === 'Automation'){
            //TEMPLATE GRAPH
            return(
                <>
                    {/* TEMPLATE BUILDER NODE SETTINGS */}
                    <div className={this.state.settingsIsOpen ? classes.onOpen : classes.onClose}>
                        {this.state.settingsIsOpen === true &&
                            <NodeSettings 
                                node={this.state.graph[this.state.selectedNode]} 
                                updateSettings={this.updateSettings}
                                updateActionSettings={this.updateActionSettings}  
                                addEvent={this.addEvent} 
                                handleEventScript={this.handleEventScript} 
                                handleEventScriptFields = {this.handleEventScriptFields}
                                addMessage={this.addMessage} 
                                handleMessage={this.handleMessage} 
                                closeNodeSettings={this.closeNodeSettings}
                                deckCategories={this.filterCategory(this.state.data.deck.categories)}
                                graph = {this.state.graph}
                                removeEvent = {this.removeEvent}
                            />
                        }
                        
                    </div>

                    <div className={!this.state.settingsIsOpen ? classes.onOpen : classes.onClose}>
                        <div style={{padding:"60px 0 0 60px"}}>
                            <p style={{fontSize:"14px", color:"#4F4F4F"}}>
                                <span style={{fontWeight:"bold", fontSize:"16px"}}>TIPS</span><br/>
                                Select a node to get started
                            </p>
                        </div>
                        <img alt="tutorial" style={{width:"150px", marginLeft:"80px"}} src={require('../../../element/jamBuilderNode.png')}/>
                        
                    </div>

                    <div className={classes.nodeSettingsOpen}>
                        <div style={{position:"relative", width:"1450px", marginLeft:"-50px"}}>
                            {this.dfs(0)}
                        </div>
                    </div>  
                </>
            ) 
        }
        else if(this.state.page === 'Settings'){
            //SETTINGS PAGE
            return(
                <div className={classes.settingsPage}>
                    {this.settingsPage(classes)}
                </div>
            )
        }
        else if(this.state.page === 'Deck Preview'){
            //DECKLIST PAGE
            return(
                <div className={classes.deckPage}>
                    {this.deckList(classes)}
                    {/* {this.deckListPage(classes)} */}
                </div>
            )
        }
        else if(this.state.page === 'Canvas Setup'){
            return(
                <div>
                    
                </div>
            )
        }
    }

    // SETTINGS PAGE
    settingsPage = (classes) =>{
        let image = require('../../../element/default-image.png')

        return(
            
            <div style={{color:"#828282", padding:"40px", maxWidth:"1200px", margin:"0 auto"}}>
                <Grid container>
                    <Grid item xs={6}>
                        {/* MODE */}
                        <div className={classes.fieldRow}>
                            <p className={classes.fieldText}>
                                <span className={classes.fieldHeader}> Mode </span><br/>
                                This will limit the number of players joining the jam
                            </p>
                            <FormControl component="fieldset">
                                <RadioGroup 
                                    aria-label="template-mode" 
                                    name="playerMode" 
                                    style={{fontSize:"14px"}}
                                    value={this.state.playerMode}
                                    onChange={event => this.handleChange(event)}
                                >
                                    <FormControlLabel value="Quick" control={<Radio />} label = {<div style={{fontSize:"14px", color:"#4F4F4F"}}><b>Quick:</b> 2 - 6 (30 min - 1 hour) <b>RECOMMENDED</b></div>}/> 
                                    <FormControlLabel value="Medium" control={<Radio />} label = {<div style={{fontSize:"14px", color:"#4F4F4F"}}><b>Medium: </b> 2 - 12 (1 - 2 hours)</div>}/>
                                </RadioGroup>
                            </FormControl>
                        </div>

                        {/* DESCRIPTION */}
                        <div className={classes.fieldRow}>
                            <p className={classes.fieldText}>
                                <span className={classes.fieldHeader}> Description * </span><br/>
                                Tell the players what the game is all about
                            </p>
                            <TextField
                                name="description"  
                                placeholder ="Eg: This is a game that involves players to take turn in telling a story"
                                onChange={event => this.handleChange(event)}
                                value={this.state.description}
                                style={{width:"500px", padding:"10px", background:"#FAF7EA", border:"1px solid #C4C4C4", borderRadius:"5px"}}
                                InputProps={{disableUnderline: true, style:{color: "#4F4F4F", fontSize:"14px", fontFamily:"Arial"}}}
                                inputProps={{maxLength: 300}}
                                multiline={true}
                                rows={5} 
                                rowsMax={5}
                            />
                        </div>


                        {/* RULEBOOK */}
                        <div className={classes.fieldRow}>
                            <p className={classes.fieldText}>
                                <span className={classes.fieldHeader}> Rulebook * </span><br/>
                                This rulebook will be displayed for others to see and learn how to play the game
                            </p>
                            <TextField
                                name="rulebook"  
                                placeholder ="Eg: Another player is allowed to interrupt the active player"
                                onChange={event => this.handleChange(event)}
                                value={this.state.rulebook}
                                style={{width:"500px", padding:"10px", background:"#FAF7EA", border:"1px solid #C4C4C4", borderRadius:"5px"}}
                                InputProps={{disableUnderline: true, style:{color: "#4F4F4F", fontSize:"14px", fontFamily:"Arial"}}}
                                inputProps={{maxLength: 700}}
                                multiline={true}
                                rows={10} 
                                rowsMax={10}
                            />
                        </div>
                                        
                        {/* PRIVACY */}
                        <div className={classes.fieldRow}>
                            <p className={classes.fieldText}>
                                <span className={classes.fieldHeader}> Privacy </span><br/>
                                This will effect the people who can host your template once you have published it
                            </p>
                            <FormControl component="fieldset">
                                <RadioGroup 
                                    aria-label="template-mode" 
                                    name="privacy" 
                                    value={this.state.privacy}
                                    onChange={event => this.handleChange(event)}
                                >
                                    <FormControlLabel value="Private" control={<Radio />} label = {<div style={{fontSize:"14px", color:"#4F4F4F"}}><b>Private</b><br/>Only you can use the template of the jam</div>}/> 
                                    <FormControlLabel value="Public" control={<Radio />} label = {<div style={{fontSize:"14px", color:"#4F4F4F"}}><b>Public</b><br/>Everyone can use and duplicate the template</div>}/>
                                </RadioGroup>
                            </FormControl>
                        </div>

                        {/* DELETE */}
                        {/* 
                        <Button className={classes.deleteButton}>
                            {this.state.published? 
                                <>Delete template</> 
                                : 
                                <>Delete draft</>
                            }
                        </Button> */}
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="flex-end" alignItems="center">
                            <Grid item>
                                <div style={{position:"relative"}}>
                                    <input
                                        accept="image/*"
                                        className={classes.input}
                                        id="imageUpload"
                                        multiple
                                        type="file"
                                        hidden
                                        // onChange = {(e)=>this.handleOpenModal(2)}
                                    />

                                    <Dropzone
                                        onDrop={this.handleDropFile}
                                        accept="image/jpeg, image/png"
                                        multiple={false}
                                        > 
                                        {({getRootProps, getInputProps, isDragActive}) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                {this.state.templateImage.url
                                                    ? 
                                                        <div style={{width:"350px", height:"180px"}}>
                                                            <label htmlFor="imageUpload">
                                                                <IconButton disabled component="span" type="file" style={{position:"absolute", top:"10px", right:"10px", background:"#6B6B6B"}}>
                                                                    <AddPhotoAlternateOutlined style={{color:"white"}}/>
                                                                </IconButton>
                                                            </label>
                                                            <img alt="media-preview" width={350} height={180} className={classes.previewImage} src={`${config.backend.uri}/static/uploads/${this.state.templateImage.url}`}/>
                                                        </div>
                                                    :  
                                                        <div style={{width:"350px", height:"180px"}}>
                                                            <label htmlFor="imageUpload">
                                                                <IconButton disabled component="span" type="file" style={{position:"absolute", top:"10px", right:"10px", background:"#6B6B6B"}}>
                                                                    <AddPhotoAlternateOutlined style={{color:"white"}}/>
                                                                </IconButton>
                                                            </label>
                                                            <img alt="media-preview" className={classes.previewImage} src={image}/>
                                                        </div>
                                                }
                                            </div>                 
                                        )}
                                    </Dropzone>
                                    <p style={{marginTop:"10px"}}>Recommended size: 350px x 180px</p>
                                    <div>
                                        <br/>
                                        <FormControl component="fieldset">
                                            <FormControlLabel
                                                control={
                                                    <Switch 
                                                    aria-label="spectate-mode" 
                                                    name="spectateMode" 
                                                    checked={this.state.spectateMode} 
                                                    onChange={event => 
                                                        {
                                                            this.handleSwitch(event)
                                                            this.props.client.mutate({ // to update game spectatorMode
                                                                mutation: GAME_UPDATE_SPECTATORMODE,
                                                                variables:{
                                                                    _id: this.state.gameLink,
                                                                    spectatorMode: event.target.checked
                                                                }
                                                                }).then((res)=>{
                                                                    console.log(res)
                                                                })
                                                        }      
                                                    } 
                                                    
                                                    color="primary"
                                                    className={classes.toggle}
                                                    />
                                                }
                                                label="Allow spectator to join after jam starts."
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                    aria-label="notify-on-turn" 
                                                    name="notifyOnTurn" 
                                                    checked={this.state.notifyOnTurn} 
                                                    onChange={event => this.handleSwitch(event)}
                                                    color="primary"
                                                    className={classes.toggle}
                                                    />
                                                }
                                                label="Notify user on their turn."
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
            </div>
        )
    }

    handleDropFile = (files) => {

        const file = files.shift();

        this.setState({
            templateImage: {...files}
        })
        //console.log(this.state.draft.toObject());
        // this.state.draft.syncImage(file);
    
        const data = new FormData();
        data.append('image', file);
    
        // const req = request.post(`${config.backend.uri}/upload`);
        const req = request.post(`${config.backend.uri}/api/upload`);
    
        req.on('progress', (event) => {
          const percent = Math.floor(event.percent);
          if (percent >= 100) {
            this.setState({ imageCompleted: 100 });
          } else {
            this.setState({ imageCompleted: percent });
          }
        });
        req.send(data);
        req.end((err, res) => {
          // this.state.draft.set('image', { ...file, ...res.body.data.upload });
          this.setState({
              templateImage: {...file, ...res.body.data.upload}
          })


          this.props.client.mutate({
            mutation: UPDATE_JAMBUILDER_IMAGE,
            variables:{
                _id: this.state.data._id,
                imageUrl: this.state.templateImage.url
            }
            }).then((res)=>{
                console.log(res)
            })
          
        });
    }



    /**
     * deckList is the newly iterated deck preview page
     */
    deckList = (classes) =>{
        const filterName = this.state.filterName

        return(
        <>
            <div style={{position:"fixed", top:"7rem", left:"0", height:"3rem", width:"100%", zIndex:"1", background:"#F2F2F2"}}>
                <Grid container style={{width:"760px", margin:"auto", marginTop:"7px"}}>
                    <Grid item xs={3}>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="center" alignItems="center">
                            <Grid item>
                                <p className={classes.deckName}>
                                    {this.state.data.deck.title}
                                </p>
                                <IconButton onClick={(event)=>{this.setState({deckAnchorEl: event.currentTarget})}} className={classes.deckEllipse}>
                                    <ExpandMore style={{fontSize:"14px"}}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container justify="flex-end" alignItems="center">
                            <Grid item>
                                {/* Filter / Sort Button */}
                                <Button style={{border:"1px solid #4F4F4F", color:"#828282", outline:"none", marginRight:"10px",'&:focus':{outline:"none"}}} 
                                        onClick={(event)=>{this.setState({filterAnchorEl: event.currentTarget})}}>
                                    <p style={{textTransform:"none", fontFamily:"Arial", margin:"0"}}>
                                        {!filterName ? "No Category" : filterName}
                                    </p>
                                    <ExpandMore style={{marginLeft:"10px", fontSize:"14px"}}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div style={{position:"relative", height:"calc(100% - 3rem)", width:"100%", marginTop:"3rem", overflow:"auto"}}>
                <Grid container style={{height:"100%", width:"800px", margin:"auto"}}>
                    {/* Display cards in deck */}
                    {this.displayCards(classes)}
                </Grid>
            </div>
            
            {/* Deck ellipse menu */}
            <Menu 
                anchorEl={this.state.deckAnchorEl}
                open={Boolean(this.state.deckAnchorEl)}
                onClose={()=>this.closeDeckMenu()}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                PaperProps={{style: {color:"#4F4F4F",border:"0.5px solid #c4c4c4", boxShadow:"none"}}}  
            >
                <ListSubheader className={classes.filterLabel}>
                    <SwapHorizRounded className={classes.deckMenuListIcon}/> 
                    <strong>Switch Deck</strong>
                </ListSubheader>
                {/* Get list of decks */}
                {this.userDecksById && this.userDecksById.customDecks.map(deck=>
                    deck.published &&
                        <MenuItem 
                            onClick={() =>{
                                this.changeDeck(deck.yamlEndpoint)
                            }} 
                            className={classes.deckMenuList}
                        >
                            {deck.deckName}
                        </MenuItem>
                )}
            </Menu >

            {/* Filter and sort drop down menu */}
            <Menu 
                anchorEl={this.state.filterAnchorEl}
                open={Boolean(this.state.filterAnchorEl)}
                onClose={()=>this.closeFilterMenu()}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical:"bottom", horizontal:"right" }}
                transformOrigin={{ vertical:"top", horizontal:"right" }}
                PaperProps={{style: {color:"#4F4F4F",border:"0.5px solid #c4c4c4", boxShadow:"none"}}}  
            >
                <ListSubheader className={classes.filterLabel}><strong>Sort By</strong></ListSubheader>
                    <MenuItem className={classes.filterItem} onClick={()=>{this.handleFilter("Category", 0)}}>Category</MenuItem>
                <ListSubheader className={classes.filterLabel}><strong>Filter By Category</strong></ListSubheader>
                    {this.getDeckCategories()}
            </Menu >
        </>)
    }

    /**
     * closeDeckMenu is to close deck name's menu which is location in the middle panel's top panel
     */
    closeDeckMenu = () => {
        this.setState({
            deckAnchorEl: null
        })
    }

    /**
     * handleFilter to handle the filter and sort system of the deck
     * @param {*} name - filter name 
     * @param {*} type - filter type (0 - Sort, 1 - Filter) By Default, the cards are sorted by CreatedOn value
     */
    handleFilter = (name, type) =>{
        this.setState({
            filterName: name,
            filterType: type,
        }, ()=>{this.closeFilterMenu()})
    }

    /**
     * closeFilterMenu to close the filter & sort dropdown menu
     */
    closeFilterMenu = () =>{
        this.setState({
            filterAnchorEl: null
        })
    }

    /**
     * sortFilterCards is to sort or/and filter the deck into their respective choices
     * Filter type (0 - Sort, 1 - Filter)
     *  If filter type is 0, the cards will only sort by "By Category" 
     *  If filter type is 1, the cards will filter by specified category
     */
    sortFilterCards = () =>{
        const {filterName, filterType} = this.state
        const {deck} = this.state.data
        let cards = deck.elements


        if(cards){
            if(filterType===0){
                //Sort by Category
                cards = cards.sort((a,b) => (a.category < b.category) ? -1 : ((b.category > a.category) ? 1 : 0))
            }

            if(filterType===1){
                //Filter by specified category
                cards = cards.filter(function(a) {return a.category === filterName;});
            }
        }

        // console.log(cards)
        return cards
    }

    /**
     * getDeckCategories is to get the selected deck's list of categories for filter menu
     */
    getDeckCategories = () =>{
        const {deck} = this.state.data
        const {classes} = this.props
        let cards = deck.elements

        let unique = [];
        let categoryList = [];
        let filterMenu = []

        for (var i=0; i<cards.length; i++) {
            let category = cards[i].category
            if( !unique[category]){
                categoryList.push(category);
                filterMenu.push(
                    <MenuItem key={i} className={classes.filterItem}
                        onClick={()=>{this.handleFilter(category, 1)}}>
                            {!category ? "No Category" : category}
                    </MenuItem>)
                unique[category] = 1;
            }
        }
        return filterMenu
    }

    /**
     * displayCards is to display cards in the selected deck
     * @param {*} classes - for styling
     */
    displayCards = (classes) =>{
        const deck = this.sortFilterCards()
        const categoryColor = this.state.data.deck.categories
        let cardArray = []

        // console.log(deck)
        var color = "#C4C4C4"
        if(deck){
            for(let i = 0; i < deck.length; i++){
                let card = deck[i]
                
                for(let j=0; j<categoryColor.length; j++){
                    if(categoryColor[j].name === card.category){
                        color = categoryColor[j].color
                    }
                }
                
                cardArray.push(
                    <LazyLoad
                        once
                        height={70}
                        offset={280}
                        overflow={true}
                    >
                        <div
                            key={`card-${i}`} 
                            className={classes.cards}
                        >
                            <div style={{height:"20%", display:"block"}}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <IconButton disabled className={classes.cardIcon} style={{background: color ? color : "#C4C4C4"}}>
                                            <Eco className={classes.cardIconSize} style={{color:"white"}}/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{height:"60%"}}>
                                {/* Category and content */}
                                <p className={classes.cardFont} style={{color: color ? color : "#C4C4C4"}}>
                                    {card.category ? card.category : "No Category"}
                                </p>
                                <p className={classes.cardFont} style={{width:"calc(100% - 20px)", overflowY: card.imgUrl? "hidden" : "auto", height:"100px"}}>
                                    {
                                    card.imgUrl ?
                                        <img 
                                            style={{width:"100%"}} 
                                            alt="img" 
                                            src={`${config.backend.uri}/static/uploads/${card.imgUrl}`}
                                        />
                                    :
                                        card.content ? card.content : 
                                        <span style={{fontWeight:"normal", color:"#c4c4c4"}}>Write your story here...</span>}
                                </p>
                            </div>
                            <div style={{height:"20%"}}>
                                {/* Label and word length */}
                                <Tooltip title={
                                    card?.label?.map((tag, index)=>(
                                        index !== card?.label?.length - 1 ?
                                        tag + ", " : tag
                                    ))}
                                    disableFocusListener={card?.label?.length > 0 ? false : true}
                                    disableTouchListener={card?.label?.length > 0 ? false : true}
                                    disableHoverListener={card?.label?.length > 0 ? false : true}
                                    >
                                    <div className={classes.tag}>
                                        <LocalOfferOutlined className={classes.tagIconSize}/>
                                            {card.label.length}
                                    </div>
                                </Tooltip>
                                <p className={classes.cardContentLength}>
                                    {150 - card.content.length}
                                </p>
                            </div>
                        </div>
                    </LazyLoad>
                )
            }
        }

        return cardArray
    }

    
    /**
     * deckListPage is the OLD deck preview
     */
    deckListPage = (classes) =>{
        const {deck} = this.state.data
        const {deckSelection} = this.state

        return(
            <>
                <div style={{width:"calc(100% - 20rem)", overflow: "auto",}}>
                    <Grid container direction="column" justify="flex-start" alignItems="flex-end">
                        {this.getCardPreview(classes, deck)}
                    </Grid>
                </div>
                <div className={classes.onOpen}>
                    <div style={{padding:"20px"}}>
                        <p style={{margin:"0", fontWeight:"bold", color:"#4F4F4F",fontSize:"18px", marginBottom:"10px"}}>
                            <FilterFrames style={{color:"#4173D6", fontSize:"16px", marginRight:"10px", marginTop:"-3px"}}/> 
                            Deck List
                        </p>
                        <p style={{fontSize:"14px", color:"#828282", margin:"0"}}>
                            View the content of the card
                        </p>
                    </div>
                    <Divider/>
                    <div style={{padding:"20px"}}>
                        <Grid container justify="flex-start" alignItems="center">
                            <Grid item xs={7}>
                                <p style={{margin:"0", color:"#4F4F4F", fontSize:"16px"}}>
                                    {this.state.data.deck.title === "Story Design Cards" ? "Once Upon a Time" : `${this.state.data.deck.title}`}
                                </p>
                                
                            </Grid>
                            <Grid item xs={5}>
                                <Grid container justify="flex-end" alignItems="center">
                                    <Button onClick={(e)=>{this.setState({tempSwitchDeckMenu: e.currentTarget})}} className={deckSelection === 'blank' ? classes.switchDeckButtonDisabled : classes.switchDeckButton} disabled = {deckSelection  === 'blank' ? true : false}>
                                        Switch Deck
                                    </Button>
                                    <Menu 
                                        anchorEl={this.state.tempSwitchDeckMenu}
                                        open={this.state.tempSwitchDeckMenu}
                                        onClose={()=>{this.setState({tempSwitchDeckMenu: null})}}
                                        PaperProps={{style: {color:"#4F4F4F",border:"0.5px solid #c4c4c4", boxShadow:"none"}}}  
                                    >
                                        <MenuItem 
                                            onClick={() =>{
                                                this.changeDeck("storyDesign")
                                            }} 
                                            className={classes.deckMenuList}
                                        >
                                            Story Design
                                        </MenuItem>
                                        {this.userDecksById && this.userDecksById.customDecks.map(deck=>
                                            <MenuItem 
                                                onClick={() =>{
                                                    this.changeDeck(deck.yamlEndpoint)
                                                }} 
                                                className={classes.deckMenuList}
                                            >
                                                {deck.deckName}
                                            </MenuItem>
                                        )}
                                    </Menu >
                                </Grid>
                            </Grid>
                        </Grid>
                    
                        <br/>
                        
                        <div style={{overflowX:"visible"}}>
                            {this.displayDeckContent(deck, deckSelection, classes)} 
                        </div>
                    </div>
                </div>
               
            </>
        )
    }

    selectDeck = (deck) => {
        this.setState({deckSelection: deck})
    }

    /**
     * getCardPreview is the OLD deck preview
     */
    getCardPreview=(classes, deck)=>{
        var deckList = [
            {deck: "blank", background:"#F5EFD2", color:"#4F4F4F", avatar:"#FFAA0D", category:"Blank"},
            {deck: "predefined", background:"#4173D6", color:"white", avatar:"white", category: deck.elements[0].category}
        ]
        var deckPreview = []

        for(let i=0; i<deckList.length; i++){
            deckPreview.push(
                <Grid item>
                    <div onClick={()=>this.selectDeck(deckList[i].deck)} className={`${classes.deckWrapper} ${this.state.deckSelection === deckList[i].deck && classes.deckSelection}`}>
                        
                        {/*CARD COVER */}
                        <Card className={classes.card}> 
                            <Card style={{background:deckList[i].background}}  className={classes.cardCover}>
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <Grid item xs={3}>
                                        </Grid>
                                        <Grid item xs={6}  className={classes.cardCoverCategory} style={{color: deckList[i].color}}>
                                            {deckList[i].category}
                                        </Grid>
                                        <Grid item xs={3} >
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" justify="center" alignItems="center" className={classes.cardCoverIconWrapper}>
                                        <Grid item xs={12}>
                                            <Tooltip title="Card’s label will appear by hovering this ">
                                                <Avatar style={{width:"120px", height:"120px", margin:"auto", background: deckList[i].avatar}}>
                                                    {deckList[i].deck === "blank" ? 
                                                    <Create style={{fontSize:"50px", color: deckList[i].background}} /> : 
                                                    <ControlCamera style={{fontSize:"50px", color: deckList[i].background}}/>}
                                                </Avatar>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className={classes.cardCoverInfo}>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Card>

                        {/*CARD CONTENT */}
                        <Card className={classes.card}> 
                            <Card style={{background: deckList[i].background}} className={classes.cardContent}>
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid item xs={3}>
                                        <Tooltip title="Card’s labels will appear by hovering this ">
                                            <Avatar style={{background: deckList[i].avatar}}>
                                                {deckList[i].deck === "blank" ? 
                                                <Create style={{color: deckList[i].background}} /> : 
                                                <ControlCamera style={{color: deckList[i].background}}/>}
                                            </Avatar>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                    </Grid>
                                    <Grid item xs={3}>
                                    </Grid>
                                </Grid>
                                
                                <Grid container className={classes.contentWrapper}>
                                    <p style={{color: deckList[i].color, fontSize:"14px", fontWeight:"bold", fontFamily:"Montserrat"}} >
                                        Card content will appear here
                                    </p>
                        
                                </Grid>
                            </Card>
                        </Card>
                    </div>
            </Grid>)
        }

        return deckPreview
    }

    displayDeckContent = (deck, deckSelection, classes) =>{
        if(deckSelection !== "blank"){
            let displayArr = []

            for(let i = 0; i < deck.elements.length; i++){
                let card = deck.elements[i]
                displayArr.push(
                    <LazyLoad
                        once
                        height={70}
                        offset={280}
                        overflow={true}
                    >
                        <div
                            id = {`card-${i}`} 
                            className={classes.deckCard}
                        >
                            <Tooltip title="Content" aria-label="Content">
                                <p className={classes.deckContent}>
                                    {card.content}
                                </p>
                            </Tooltip>
                            <Tooltip title="Category" aria-label="Category">
                                <p className={classes.deckLabel}>
                                    <Apps className={classes.deckLabelIcon}/>
                                    {card.category}
                                </p>
                            </Tooltip>
                            {card.label.map((label) =>{
                                return(  
                                    <Tooltip title="Label" aria-label="Label">
                                        <p className={classes.deckLabel} style={{background:"#967B7B"}}>
                                            <LocalOfferOutlined className={classes.deckLabelIcon}/>
                                            {label}
                                        </p>
                                    </Tooltip>
                                )
                            })}
                        </div>
                    </LazyLoad>
                )
            }
    
            return displayArr
        }
        else{
            return(
                <div style={{marginTop:"20px"}}>
                    <img alt="banner" style={{width:"150px", marginLeft:"60px"}} src={require('../../../element/cat.png')}/>
                    <p style={{color:"#C4C4C4", textAlign:"center", fontSize:"14px"}}>This is a deck of blank cards are left empty to be filled during jams</p>
                </div>
            )
        }
    }

    handleSwitch = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        },()=>{
            const {rulebook, description, privacy, playerMode, data, spectateMode, notifyOnTurn,gameLink} = this.state
            const general = {
                name: "Game Jam",
                deckEndpoint: data.deckEndpoint,
                rulebook: rulebook,
                description: description,
                privacy: privacy,
                playerMode: playerMode,
                spectateMode: spectateMode,
                notifyOnTurn: notifyOnTurn,
                gameLink: gameLink,
            }

            JamBuilderUtil.saveGeneralSettings(general)
        })
    }

    handleChange = (event) =>{
        //TLDR for this function:
        //if target.name is 'description', the state.description will change
        this.setState({
            [event.target.name]: event.target.value
        }, () =>{
            const {rulebook, description, privacy, playerMode, data,spectateMode, notifyOnTurn,gameLink} = this.state
            const general = {
                name: "Game Jam",
                deckEndpoint: data.deckEndpoint,
                rulebook: rulebook,
                description: description,
                privacy: privacy,
                playerMode: playerMode,
                spectateMode: spectateMode, 
                notifyOnTurn: notifyOnTurn,
                gameLink: gameLink,
            }

            JamBuilderUtil.saveGeneralSettings(general)
        });
    }

    handleTitleChange = (event) =>{
        //React recycles event objects for performance reasons, 
        //so by the time setState is called, event properties has already been null, therefore storing
        //target.value into a variable is the solution
        let textValue = event.target.value
        this.setState(state =>{
            state.data.name = textValue

            return state
        })
    }

    openPopper = (e) =>{
        this.setState({
            anchorEl: e.currentTarget
        });
    }

    closePopper = () =>{
        this.setState({
            anchorEl: null
        });
    }

    async getSession(){
        if(this.state.userCheck === false){
            await session.fetchLocal();
            const user = session.getUser();

            userAUTH(user,this.props.history) 

            if(user._id){
              this.setState({
                user: user,
              })
            }
      
            this.setState({
              userCheck: true
            })
        }
    }

    getGraph = () => {
        if(this.state.realGraphCheck === false){
            let jbid = JamBuilderUtil.getJBID()
            let deck = null
            this.props.client.query({
                query: GET_JAMBUILDER_BY_ID(jbid),
            }).then((status)=>{
                let result = status.data.jamBuilderById
                let endpoint = result.deckEndpoint
                let existingDeck = result.deck
                //console.log(existingDeck)
                // console.log(result.general)
                if(existingDeck){
                    // console.log(existingDeck)
                    this.setState(prevState =>({
                        data: {
                            _id: result._id,
                            name: result.name,
                            createdBy: result.createdBy,
                            configIndex: result.configIndex,
                            deckEndpoint: endpoint,
                            deck: existingDeck,
                        },
                        realGraph: result.graph,
                        realGraphCheck: true,
                        graph: this.graphSelection(result._id, result.graph, result.templateType),

                        rulebook: result?.general?.rulebook ?? prevState.rulebook,
                        description: result?.general?.description ?? prevState.description,
                        privacy: result?.general?.privacy ?? prevState.privacy,
                        playerMode: result?.general?.playerMode ?? prevState.playerMode,
                        spectateMode: result?.general?.spectateMode ?? prevState.spectateMode,
                        notifyOnTurn: result?.general?.notifyOnTurn ?? prevState.notifyOnTurn, 
                        gameLink: result?.general?.gameLink ?? prevState.gameLink,

                        templateImage: {
                            url: result.image
                        }
                    }))
                }else{
                    let deckAPI = `/api/game/getCustomDeck`
                    let p = {
                        endpoint,
                        userDir: `${result.createdBy._id}-${replaceWhiteSpace(result.createdBy.name.first)}`,
                    }

                    if(endpoint === "storyDesign"){
                        deckAPI = `/api/game/getDeck`
                        p = {
                            endpoint,
                        }
                    }

                    axios.get(`${config.backend.uri}${deckAPI}`,{params: p}).then((res)=>{
                        deck = res.data.deck
                        this.setState(prevState =>({
                            data: {
                                _id: result._id,
                                name: result.name,
                                createdBy: result.createdBy,
                                configIndex: result.configIndex,
                                deckEndpoint: endpoint,
                                deck: deck,
                            },
                            realGraph: result.graph,
                            realGraphCheck: true,
                            graph: this.graphSelection(result._id, result.graph, result.templateType),

                            rulebook: result?.general?.rulebook ?? prevState.rulebook,
                            description: result?.general?.description ?? prevState.description,
                            privacy: result?.general?.privacy ?? prevState.privacy,
                            playerMode: result?.general?.playerMode ?? prevState.playerMode,
                            spectateMode: result?.general?.spectateMode ?? prevState.spectateMode,
                            notifyOnTurn: result?.general?.notifyOnTurn ?? prevState.notifyOnTurn, 
                            gameLink: result?.general?.gameLink ?? prevState.gameLink,

                            templateImage: {
                                url: result.image
                            }
                        }))
                    })
                }

                this.props.client.query({
                    query: GET_USER_DECKS(result.createdBy._id),
                }).then((status)=>{
                    this.userDecksById = status.data.userDecksById
                    this.userDecksById.customDecks.push({
                        _id: "default",
                        deckDump: deck,
                        deckName: "Story Design Cards",
                        yamlEndpoint: "storyDesign",
                        categoryColorMap: new Map([
                            ["Story", "#FF8BA7"],
                            ["Money", "#2CB67D"],
                            ["Request", "#3DA9FC"],
                            ["Genre", "#A31B1B"],
                            ["Topic", "#D08B5B"],
                            ["Premises", "#1A49A5"],
                            ["Modifier", "#219653"],
                            ["Character", "#5B3680"],
                            ["Archetype", "#FFC0C0"],
                            ["Conflict", "#FFAA0D"],
                            ["Crisis", "#4173D6"],
                            ["Issues", "#53CAA6"],
                            ["Resolution", "#7956C1"],
                            ["Dramatic Question", "#219653"],
                            ["Loglines", "#4173D6"],
                            ["Taglines", "#5B3680"],
                            ["Story Messages", "#D08B5B"],
                        ]),
                        published: true,
                        listOfCategories: [
                            {category: "Story"},
                            {category: "Money"},
                            {category: "Request"},
                            {category: "Genre"},
                            {category: "Topic"},
                            {category: "Premises"},
                            {category: "Modifier"},
                            {category: "Character"},
                            {category: "Archetype"},
                            {category: "Conflict"},
                            {category: "Crisis"},
                            {category: "Issues"},
                            {category: "Resolution"},
                            {category: "Dramatic Question"},
                            {category: "Loglines"},
                            {category: "Taglines"},
                            {category: "Story Messages"}, 
                        ],
                        listOfLabels: [
                            {label:"Marker"}
                        ],
                    })
                })

            }).catch((err)=>{
                console.log(err)
            })
        }
    }

    graphSelection = (_id, queryGraph, templateType) =>{
        if(isEmpty(queryGraph)){
            //Saving the graph as if I don't save there is a potential that the
            //Jambuilder will query the latest template and not the one they created with
            if(templateType === "basic"){
                JamBuilderUtil.saveGraph(_id, defaultTemplate)
                return defaultTemplate
            }
            else if(templateType=== "story"){
                JamBuilderUtil.saveGraph(_id, storyGameTemplate)
                return storyGameTemplate
            }
            else if(templateType=== "freeStyle"){
                JamBuilderUtil.saveGraph(_id, freeStyleTemplate)
                return freeStyleTemplate
            }
        }
        else{
            return queryGraph
        }
    }

    interpretGraph = () => {
        // console.log(this.state.graph)
        const {classes} = this.props
        const {rulebook, description, privacy, playerMode,spectateMode,notifyOnTurn,gameLink} = this.state
        let general = {
            name: this.state.data.name,
            deckEndpoint: this.state.data.deckEndpoint,
            rulebook: rulebook,
            description: description,
            privacy: privacy,
            playerMode: playerMode,
            spectateMode,
            notifyOnTurn,
            gameLink,
        }

        this.props.client.mutate({
            mutation: JAMBUILDER_INTERPRETER,
            variables:{
                _id: this.state.data._id,
                graph: this.state.graph,
                graphName: this.state.data.name,
                creator: this.state.data.createdBy,
                newIndex: this.state.data.configIndex,
                general: general,
                notifyOnTurn,
                spectateMode

            }
        }).then((response) =>{
            this.setState({
                loading: false
            },() =>{
                const {error = false, errorLog = {}} = response.data.jamBuilderInterpreter.interpreterMessage
                if(error){
                    this.setState({
                        errorNodes: errorLog
                    }, ()=>{
                        let errorMessage = "Error at: "
    
                        for(const [errorIndex, errorNodeName] of Object.entries(errorLog)){
                            errorMessage += errorNodeName  
                        }
    
                        errorMessage += "\nAre these names unique?"
        
                        //Based on the object of duplicates in errorLog, highlight which nodes
                        // toast.error(errorMessage,{
                        //     className: classes.toastError,
                        //     autoClose: false,
                        // })
                        this.nodeError("Publish is not successful", errorMessage)
                    });
                }
                else{
                    this.handleOpenModal(1)
                    // toast.success('Created a new game succesfully!', {
                    //     className: classes.toastSuccess
                    // });

                    this.props.client.mutate({
                        mutation: GAME_JAMBUILDER_SETUP,
                        variables:{
                            endpoint: this.state.data.configIndex,
                            gameLink: this.state.gameLink,
                            builderID: this.state.data._id,
                        }
                    })
                }
            }); 
        }).catch((error) => {
            this.setState({
                loading:false
            },()=>{
                let errorMessage = "An error has occured."
                // toast.error(errorMessage,{
                //     className: classes.toastError,
                //     autoClose: true,
                // })
                this.nodeError("Publish is not successful", errorMessage)
            })
        })
    }

    closeNodeSettings = () =>{
        this.setState({ 
            settingsIsOpen: false, 
            selectedNode: null,
            selectedNodeId: null
        })
    }

    openNodeSettings = (id) => {
        if(id !== 0){
            let index = null
            let tempErrorNode = null

            if(this.state.errorNodes != null && this.state.errorNodes.hasOwnProperty(id)){
                tempErrorNode = this.state.errorNodes
                delete tempErrorNode[id]
            }

            for(let i=0; i< this.state.graph.length; i++){
                if(this.state.graph[i].id === id){
                    index = i
                }
            }

            this.setState(prev =>({
                errorNodes: tempErrorNode ?? prev.errorNodes,
                selectedNode: index,
                selectedNodeId: id, 
                settingsIsOpen: true, 
            }))
        }
        
    }

    dfsUtil = (id, explored, graph, array, depth) =>{
        const {classes} = this.props
        var childNode = []
        var currentNode = null
        for(var j=0; j<explored.length; j++){
            if(explored[j].id===id){ //mark node as visited
                explored[j].visited = true
            }
        }

        for(j=0; j<graph.length; j++){
            if(graph[j].id===id){ //get list of child nodes
                childNode = graph[j].childNode
                currentNode = graph[j]
            }
        }
        var totalChild = childNode.length
        var noChild = 0
        var arrowDepth = 0
        var previousDepth = 0
        while(childNode.length > 0){ //while there's still children node to explore
            var exists = false
            var child = childNode[0]

            noChild++
            childNode = childNode.slice(1)

            for(j=0; j<explored.length; j++){ 
                if(explored[j].id===child && explored[j].visited){ //if child has been visited, no need to bother
                    exists = true
                }
            }
            
            if (!exists){ //if child hasn't been visited, explore the child
                var depthPosition = depth 
                
                if(noChild > 1){ 
                    depthPosition += 1
                    arrowDepth = depthPosition - previousDepth 
                }

                
                var result = this.dfsUtil(child, explored, graph, array, depthPosition)
                
                depth = result[0] 
                array = result[1]


                for(j=graph.length-1; j>0; j--){
                    if(graph[j].id===child){ //get child node data
                        var childData = graph[j]
                        var childIndex = j
                    }
                }


                array.push(this.node(childIndex, childData, depthPosition, noChild, arrowDepth, totalChild))
                previousDepth = depthPosition
            }
        }
        if(id===0){
            array.push(
                <div key={0} className={classes.nodeStart}>
                    Start
                </div>
            )
            return array
        }
        return [depth, array]
    }

    dfs = (node) =>{
        const graph = this.state.graph
        var explored = []
        var array = []
        var depth = 0

        for(let i=0; i< graph.length; i++){ //define a matrix of explored nodes, set everything to false
            explored = [{id: graph[i].id, visited: false}, ...explored]
            //explored.push({id: graph[i].id, visited: false})
        }

        array = this.dfsUtil(node, explored, graph, array, depth)

        return array
    }

    node = (nodeIndex, node, depth, noChild, arrowDepth, childLength) =>{
        const {classes} = this.props
        let col = 0
        let icon = null
        if(node.type === "Phase"){
            col=1
            icon = (<AccountTreeOutlined className={classes.nodeOddIcon}/>)
        }else if(node.type === "Turn Structure"){
            col=2
            icon = (<Timeline className={classes.nodeEvenIcon}/>)
        }else if(node.type === "Turn Type"){
            col=3
            icon = (<TransferWithinAStation className={classes.nodeOddIcon}/>)
        }else if(node.type === "Role"){
            col=4
            icon = (<PersonOutline className={classes.nodeEvenIcon}/>)
        }else if(node.type === "Steps"){
            col=5
            icon = (<ClearAll className={classes.nodeOddIcon}/>)
        }else if(node.type === "Key Action"){
            col=6
            icon = (<VpnKeyOutlined className={classes.nodeEvenIcon}/>)
        }else if(node.type === "Action"){
            col=7
            icon = (<FlashOnRounded className={classes.nodeOddIcon}/>)
        }

        let left = col * 180
        let top = depth * 130

        return(
            <div
                key={"node " + node.id}
                className={`
                    ${classes.node} 
                    ${this.state.selectedNode === nodeIndex && classes.nodeSelected }
                    ${this.state.errorNodes != null && this.state.errorNodes.hasOwnProperty(node.id) && "nodeErrorHighlight"}
                    ${this.state.warningNodes != null && this.state.warningNodes.hasOwnProperty(node.id) && "nodeWarningHighlight"}
                `} 
                style={{ 
                    marginTop: top, 
                    marginLeft: left,   
                }}  
            >
                {node.id!==0?
                    noChild===1?
                    this.arrow(node.id, node.type)
                    :
                    this.arrowBend(arrowDepth, node.id)
                :
                null}
                <IconButton className={classes.nodeRemove} onClick={(e)=>this.openNodeMenu(nodeIndex, node.id, e)} >
                    <MoreVert style={{fontSize:"14px"}}/>
                </IconButton>
                <div style={{position:"relative", zIndex:"0", overflow:"hidden", borderRadius:"10px"}} onClick={(e)=>this.openNodeSettings(node.id)}>
                    <p className={classes.nodeType}>
                        {icon} 
                        {node.type}
                        
                    </p>
                    <Divider/>
                    <p className={`${classes.nodeAttribute}`} key = {node.id}>
                        {
                            // node.name && node.name === "Add Card" ? 
                            //     node["Add Card"].cardType === "Specific" ?  
                            //         "Add " + node["Add Card"].card + " Card" 
                            //         : 
                            //         "Add Any Card"
                            // :
                            node.name && node.type==="Action" && node.name !== "Pass Turn" ?
                                this.actionNodeName(node.name, node.card)
                            :
                                node.name.length > 19 ? node.name.slice(0, 16).concat("...") : node.name 
                        }
                    </p>
                </div>
                {
                    col !== 0 &&
                    this.checkAddRow(node)
                }

                {/* NODE ELLIPSIS MENU */}
                <Menu
                    anchorEl={this.state.nodeAnchorEl}
                    keepMounted
                    open={this.state.selectedNodeId === node.id && this.state.nodeAnchorEl}
                    onClose={()=>this.closeNodeMenu()}
                    PaperProps={{style: {color:"#4F4F4F",border:"0.5px solid #c4c4c4", boxShadow:"none"}}}  
                >
                    <MenuItem 
                        onClick={() =>{
                            this.moveNodeUp(node, nodeIndex)
                            this.closeNodeMenu()
                        }} 
                        className={classes.nodeMenu}
                    >
                        {/* Move node up*/}
                        <Grid container>
                            <Grid item xs={2}>
                                <ArrowUpwardRounded className={classes.nodeMenuIcon}/> 
                            </Grid>
                            <Grid item xs={5}>
                                Move up
                            </Grid>
                            <Grid item xs={5}>
                                <div style={{float:"right", color:"#828282"}}>Up</div>
                            </Grid>
                        </Grid>
                    </MenuItem>
                    <MenuItem 
                        onClick={() =>{
                            this.moveNodeDown(node, nodeIndex)
                            this.closeNodeMenu()
                        }} 
                        className={classes.nodeMenu}
                    >
                        {/* Move node down*/}
                        <Grid container>
                            <Grid item xs={2}>
                                <ArrowDownwardRounded className={classes.nodeMenuIcon}/> 
                            </Grid>
                            <Grid item xs={5}>
                                Move down
                            </Grid>
                            <Grid item xs={5}>
                                <div style={{float:"right", color:"#828282"}}>Down</div>
                            </Grid>
                        </Grid>
                    </MenuItem>
                    <MenuItem 
                        onClick={() =>{
                            this.removeNode(node.id)
                            this.closeNodeMenu()
                        }} 
                        className={classes.nodeMenu}
                        disabled={childLength > 1 && node.type!=="Turn Type" ? false : true}
                    >
                        {/* Delete node*/}
                        <Grid container>
                            <Grid item xs={2}>
                                <DeleteOutlined className={classes.nodeMenuIcon}/> 
                            </Grid>
                            <Grid item xs={5}>
                                Delete
                            </Grid>
                            <Grid item xs={5}>
                                
                            </Grid>
                        </Grid>
                    </MenuItem>
                </Menu>
                {/* END OF NODE ELLIPSIS MENU */}
            </div>
        )
    }

    /**
     * OpenNodeMenu is to open the ellipsis menu for nodes
     * @param {*} index is the node's index
     * @param {*} id is the node's id
     */
    openNodeMenu = (index, id, event) =>{
        this.setState({
            nodeAnchorEl: event.currentTarget,
            selectedNode: index,
            selectedNodeId: id, 
        })
    }

    /**
     * closeNodeMenu is to close the ellipsis menu for nodes
     */
    closeNodeMenu = () =>{
        this.setState({
            nodeAnchorEl: null,
        })
    }

    /**
     * moveNodeDown is to move the node by (1) row down
     * @param {*} node is the targeted node data
     * @param {*} nodeIndex is the targeted node index
     */
    moveNodeDown = (node, nodeIndex) =>{
        const {graph} = this.state
        const parentId = node.parentNode //current parent's id
        let error = true 
        let tempGraph = graph
        let newParentIndex = null //new parent's index
        let newParentId = null //new parent's id

        //get the parent's data
        let parentData = graph.find(val => val.id === parentId)
        let parentIndex = graph.findIndex(val => val.id === parentId)
        
        //check if it's the only child node 
        if(parentData.childNode.length > 1){
            //if node index is the last index of the parent's child node array & if it's not a turn type node
            if(parentData.childNode.indexOf(node.id) === parentData.childNode.length - 1  && node.type!=="Turn Type"){
                //check if there's a new parent node that comes after the current parent node
                newParentId = this.newParentUtil(parentData, node, newParentId, "down")
                newParentIndex = graph.findIndex(val => val.id === newParentId)

                //if there's a new parent node
                if(newParentIndex !== -1 && newParentIndex != null){
                    //remove from ori parent
                    tempGraph[parentIndex].childNode.splice(parentData.childNode.indexOf(node.id), 1); 
                    //add to new parent
                    tempGraph[newParentIndex].childNode.unshift(node.id)

                    //update node's new parent id
                    tempGraph[nodeIndex].parentNode = newParentId

                    error = false
                }
                //else node can't move
            }
            else if(parentData.childNode.indexOf(node.id) !== parentData.childNode.length - 1){
                //swap node index with another node index in parent's child array
                var curNodeIndex = parentData.childNode.indexOf(node.id) 
                var nextNodeIndex = parentData.childNode.indexOf(node.id) + 1 

                var tempNodeId = parentData.childNode[nextNodeIndex]
                tempGraph[parentIndex].childNode[nextNodeIndex] = parentData.childNode[curNodeIndex]
                tempGraph[parentIndex].childNode[curNodeIndex] = tempNodeId

                error = false
            }
            
            if(!error){
                this.setState({
                    graph: tempGraph
                },
                ()=>{
                    JamBuilderUtil.saveGraph(this.state.data._id, this.state.graph)
                })
            }
            
        }

        //error message
        if(error){
            let message = (<>There needs to be at least {node.type==="Turn Type" ? 2 : 1} node <br/>
                        of this type in every branch</>)
            this.nodeError("Move is not successful", message)
        }
    }

    /**
     * moveNodeUp is to move the node by (1) row up
     * @param {*} node is the targeted node data
     * @param {*} nodeIndex is the targeted node index
     */
    moveNodeUp = (node, nodeIndex) =>{
        const {graph} = this.state
        const parentId = node.parentNode //current parent's id

        let error = true 
        let tempGraph = graph
        let newParentIndex = null //new parent's index
        let newParentId = null //new parent's id

        //get the parent's data
        let parentData = graph.find(val => val.id === parentId)
        let parentIndex = graph.findIndex(val => val.id === parentId)

        //check if it's the only child node 
        if(parentData.childNode.length > 1){
            //if node index is the 1st index of the parent's child node array & if it's not a turn type node
            if(parentData.childNode.indexOf(node.id) === 0 && node.type!=="Turn Type"){
                //check if there's a new parent node that comes after the current parent node
                newParentId = this.newParentUtil(parentData, node, newParentId, "up")
                newParentIndex = graph.findIndex(val => val.id === newParentId)

                //if there's a new parent node
                if(newParentIndex !== -1 && newParentIndex != null){
                    //remove from ori parent
                    tempGraph[parentIndex].childNode.splice(parentData.childNode.indexOf(node.id), 1); 
                    //add to new parent
                    tempGraph[newParentIndex].childNode.push(node.id)

                    //update node's new parent id
                    tempGraph[nodeIndex].parentNode = newParentId

                    error = false
                }
                //else node can't move
            }
            else if(parentData.childNode.indexOf(node.id) !== 0){//swap node index with another node index in parent's child array
                var curNodeIndex = parentData.childNode.indexOf(node.id) 
                var nextNodeIndex = parentData.childNode.indexOf(node.id) - 1 

                var tempNodeId = parentData.childNode[nextNodeIndex]
                tempGraph[parentIndex].childNode[nextNodeIndex] = parentData.childNode[curNodeIndex]
                tempGraph[parentIndex].childNode[curNodeIndex] = tempNodeId

                error = false
            }

            if(!error){
                this.setState({
                    graph: tempGraph
                }, ()=>{
                    JamBuilderUtil.saveGraph(this.state.data._id, this.state.graph)
                })
            }
        }
        
        //error message
        if(error){
            let message = (<>There needs to be at least {node.type==="Turn Type" ? 2 : 1} node <br/>
                        of this type in every branch</>)
            this.nodeError("Move is not successful", message)
        }
    }

    /**
     * nodeError is to show the toastify error for node
     * @param {*} node is the targeted node data
     */
    nodeError = (title, message) =>{
        const {classes} = this.props
        toast(({ closeToast }) => 
                <Grid container direction="row" justify="flex-start" alignItems="center"
                style={{borderLeft:"5px solid #E45858", margin:"-10px", padding:"1em"}}>
                    <Grid item xs={2}>
                        <Avatar 
                            style={{float:"left", marginRight:"10px", width:"40px", height:"40px", border:"2px solid white"}} 
                            src={require('../../../element/abu.png')}>
                        </Avatar> 
                    </Grid>
                    <Grid item xs={10}>
                        <p style={{color:"#E45858", fontWeight:"bold", margin:"0", marginLeft:"5px", fontSize:"12px"}}>
                            {title}
                        </p>
                        <p style={{margin:"0", overflowWrap: "break-word", wordWrap: "break-word", marginLeft:"5px",fontSize:"12px"}}>
                            {message}
                        </p>
                    </Grid>        
                </Grid>,
            {
                className:classes.toast,
                position:"bottom-left",
                autoClose: true,
                progressClassName: classes.toastBar
            })
    }

    /**
     * newParentUtil finding the new parent node when moving down and up
     * @param {*} node is the current node to be inspected 
     * @param {*} targetNode is the node to be moved
     * @param {*} newParentId is the result of the new parent id
     * @param {*} type is the type of node move (either "up" or "down")
     */
    newParentUtil = (node, targetNode, newParentId, type) =>{
        const {graph} = this.state
        let childId = node.id
        let parentId = node.parentNode
        let nextChildId = null
        
        //get parent node data
        const parentData = graph.find(val => val.id === parentId)

        if(parentData){
            //if parent's child array has more than 1
            if(parentData.childNode.length > 1){
                const index = parentData.childNode.indexOf(childId) //index in parent's child array

                //if to move node up
                if(type==="up" && index !== 0){ //if the child index is not 1st
                    nextChildId = parentData.childNode[index - 1] //next id of branch
                }

                //if to move node down
                if(type==="down" && index !== parentData.childNode.length - 1){ //if the child index is not 1st
                    nextChildId = parentData.childNode[index + 1] //next id of branch
                }

                while(nextChildId){ //find the new parent's id
                    let nextChildData = graph.find(val => val.id === nextChildId)
                    let child = graph.find(val => val.id === nextChildData.childNode[0])
                    

                    if(child.type === targetNode.type){
                        return nextChildData.id
                    }
                    
                    nextChildId = nextChildData.childNode[nextChildData.childNode.length-1]
                }
            }

            if(parentData.childNode.length < 2 || !nextChildId){
                //keep looping until find the correct branch 
                newParentId = this.newParentUtil(parentData, targetNode, newParentId, type)
            }

        }
        
        return newParentId
    }

    actionNodeName = (name, card) =>{
        var names = name.split(" ");
        let nodeName = (
            <>
            {names[0]} 
            {card.length > 0 ? 
                card.length === 1?
                    <span style={{color:"orange"}}>
                        {' "' + card[0].category +'" '}
                    </span> 
                    :
                    <span style={{color:"#828282", fontWeight:"normal"}}>
                        {" [Multiple] "} 
                    </span>
                : 
                <span style={{color:"#828282", fontWeight:"normal"}}>
                    {" [Any] "}
                </span>
            } 
            {names[1]}
            </>)

        return nodeName.length > 19 ? nodeName.slice(0, 16).concat("...") : nodeName
    }
    removeNode = (nodeId) =>{
        var tempGraph = this.state.graph

        for(var i=0; i<tempGraph.length; i++){
            if(tempGraph[i].childNode.find(element => element === nodeId)){
                for(var j=0; j<tempGraph[i].childNode.length; j++){
                    if(tempGraph[i].childNode[j] === nodeId){
                        tempGraph[i].childNode.splice(j, 1)
                    }
                }
            }
        }

        tempGraph = this.removeNodeUtil(tempGraph, nodeId)

        this.setState({ 
            settingsIsOpen: false,
            selectedNode: null,
            selectedNodeId: null,
            graph: tempGraph
        }, ()=>{
            JamBuilderUtil.saveGraph(this.state.data._id, this.state.graph)
        })
    }

    removeNodeUtil = (graph, id) =>{
        
        var childNode = []
        
        for(var j=0; j<graph.length; j++){
            if(graph[j].id===id){ 
                childNode = graph[j].childNode
                graph.splice(j, 1)
            }
        }
        while(childNode.length > 0){ 
            var child = childNode[0]
            childNode = childNode.slice(1)
            graph = this.removeNodeUtil(graph, child)
        }
        
        return graph
    }

    arrow = (node, nodeType) =>{
        const {classes} = this.props
        const {graph, selectedNodeId} = this.state
        let nodeChild = null

        if(selectedNodeId){
            let selectedNodeIndex = graph.findIndex(val => val.id === selectedNodeId)

            if(graph[selectedNodeIndex].childNode){
                nodeChild = graph[selectedNodeIndex].childNode[0]
            }
        }
        
        return (
            <div className={classes.nodeArrow}>
                <div className={`${classes.nodeCircleLeft} ${node === nodeChild && classes.nodeSelected }`}></div>
                <div className={`${classes.nodeCircle} ${node === selectedNodeId && classes.nodeSelected }`}></div>
            </div>
        )
    }

    arrowBend = (depth, node) =>{
        const {classes} = this.props
        const {selectedNodeId} = this.state

        var height = depth * 130
        var marginTop = -height
        
        return (
            <div className={classes.nodeArrowBend}>
                <div style={{height:height, width:"2px", background:"#828282", marginTop:marginTop}}></div>
                <div className={`${classes.nodeCircle} ${node === selectedNodeId && classes.nodeSelected }`}></div>
            </div>
            )
    }

    checkAddRow = (node) =>{
        if(node.type === "Turn Type" || node.type === "Key Action"){
            let parentNode = this.state.graph.filter(parentNode => parentNode.id === node.parentNode)

            if(parentNode[0].childNode.length !== 2){
                return (
                    <IconButton className={this.props.classes.addIcon} onClick={()=>this.addRow(node)}>
                        <AddCircleOutline />
                    </IconButton>
                )
            }
        }
        else{
            return (
                <IconButton className={this.props.classes.addIcon} onClick={()=>this.addRow(node)}>
                    <AddCircleOutline />
                </IconButton>
            )
        }
    }

    addRow = (node) => { 
        let tempGraph = JSON.parse(JSON.stringify(this.state.graph))
        let startId = 0
        let start = 0
        let newNodes = []
        let parentNode = null

        for(let i=0; i<tempGraph.length; i++){
            if(tempGraph[i].id>startId){
                startId = tempGraph[i].id   
            }
        }

        startId = startId + 1
                
        let template = addRowTemplate

        if(node.type==="Phase"){
            start = 0
        }else if(node.type==="Turn Structure"){
            start = 1
        }else if(node.type==="Turn Type"){
            start = 2
        }else if(node.type==="Role"){
            start = 3
        }else if(node.type==="Steps"){
            start = 4
        }else if(node.type==="Key Action"){
            start = 5
        }else if(node.type==="Action"){
            start = 6
        }

        //adding the node into the root parent's child nodes
        for(let j=0; j<tempGraph.length; j++){
            if (tempGraph[j].childNode.includes(node.id)){
                parentNode = tempGraph[j].id //get root parent id
                let k = tempGraph[j].childNode.indexOf(node.id)
                tempGraph[j].childNode.splice(k+1, 0, startId)
                break
            }
        }
        
        if(start >= 2 && start <=6){ //If user adds a new node aside from phase and turn structure
            for(let i=start; i<template.length-5; i++){
                template[i].parentNode = parentNode
                template[i].id = startId
               
    
                if(template[i].type==="Key Action" && start === 5){
                    if(node.name === "Unlimited"){
                        template[i].name = "Single"
                    }
                    else{
                        template[i].name = "Unlimited"
                    }
                }

                parentNode = startId //set parentNode to next new id
                if(template[i].type!=="Action"){
                    template[i].childNode = []
                    template[i].childNode.push(startId+1)
                }
        
                newNodes.push(template[i])
                
                startId ++ 
            }    
        }
        else{
            let tsParent = null
            for(let i=start; i<template.length; i++){
                if(template[i].type === "Turn Structure"){
                    tsParent = startId
                }

                template[i].parentNode = parentNode
                if(template[i].type === "Turn Type"){
                    template[i].parentNode = tsParent
                }
                template[i].id = startId
                parentNode = startId //set parentNode to next new id

                if(template[i].type==="Turn Structure"){
                    template[i].childNode = []
                    template[i].childNode.push(startId+1)
                    template[i].childNode.push(startId+6)
                }
                else if(template[i].type!=="Action"){
                    template[i].childNode = []
                    template[i].childNode.push(startId+1)
                }

        
                newNodes.push(template[i])
                
                startId ++ 
            }    
            
        }
        
        tempGraph = tempGraph.concat(newNodes)

        this.setState({
            graph: tempGraph
        }, () =>{
            JamBuilderUtil.saveGraph(this.state.data._id, this.state.graph)
        })
    }
    /**
     * FUNCTIONS FOR BASIC SETTINGS
     **/
    updateSettings = () => (event) =>{
        let index = this.state.selectedNode
        let name = event.target.name
        let value = event.target.value
        let tempGraph = this.state.graph
        
        // tempGraph[index][name] = name === "name" ? value : null

        if(name==="name"){
            tempGraph[index].name = value

            if(tempGraph[index].type==="Turn Type" || tempGraph[index].type==="Key Action"){
                let i = tempGraph[index].parentNode 
                let childNode = tempGraph[i].childNode 
                let secondId = null
                for(var j=0; j<childNode.length; j++){
                    if(childNode[j] !== tempGraph[index].id){
                        secondId = childNode[j]
                    }
                }
                if(tempGraph[index].type==="Turn Type"){
                    if(value==="In Turn"){
                        tempGraph[secondId].name = "Out Turn"
                    }
                    else{
                        tempGraph[secondId].name = "In Turn"
                    }
                }
                else if(tempGraph[index].type==="Key Action" && childNode.length > 1){
                    if(value==="Unlimited"){
                        tempGraph[secondId].name = "Single"
                    }
                    else{
                        tempGraph[secondId].name = "Unlimited"
                    }
                }
            }

            if(tempGraph[index].type==="Action"){
                tempGraph[index].card = []
            }
        }
        else if(name==="repeat"){
            tempGraph[index].repeat = value
        }
        else if(name==="repeatNum"){
            tempGraph[index].repeatNum = value
        }
        else if(name==="targetedRole"){
            tempGraph[index].targetedRole = value
        }
        else if(name==="endStep"){
            tempGraph[index].endStep = value
        }
        else if(name==="shiftTo"){
            tempGraph[index].shiftTo = value
        }

        this.setState({
            graph: tempGraph
        }, ()=>{
            JamBuilderUtil.saveGraph(this.state.data._id, this.state.graph)
        })
    }

    updateActionSettings = (event, action,  name, cardVal = null) => {
        let index = this.state.selectedNode
        let value = event.target.value
        let tempGraph = this.state.graph

        if(action === "Add Card"){
            tempGraph[index][action] = {
                placement: name === "placement" ? value  : tempGraph[index][action]["placement"] ?? null,
                // cardType : name === "cardType" ? value : tempGraph[index][action]["cardType"] ?? null,
                // card : name === "card" ? cardVal : tempGraph[index][action]["card"] ?? null,
            }
        }
        else if(action === "Pass Turn"){
            tempGraph[index][action] = {
                passType: name === "passType" ? value : null,
            }
        }

        if(action !== "Pass Turn"){
            if (name === "card") {
                tempGraph[index].card = cardVal
            }
            
            // console.log(index)
            // console.log(tempGraph[index].card)
            // console.log(tempGraph[index].name)
        }
         
        this.setState({
            graph: tempGraph
        }, ()=>{
            JamBuilderUtil.saveGraph(this.state.data._id, this.state.graph)
        })
    }

    addMessage = () =>{
        var tempGraph = this.state.graph
        var index = this.state.selectedNode

        var tempMessage = tempGraph[index].instruction

        var message = {
            role: "Storyteller",
            message: [  {type:"Default", check: true, message:""}, 
                        {type:"Interrupted", check: true, message:""}],
        }

        tempMessage.push(message)
        tempGraph[index].instruction = tempMessage

        this.setState((prev)=>({ 
            graph:tempGraph
        }), ()=>{
            JamBuilderUtil.saveGraph(this.state.data._id, this.state.graph)
        })
    }

    handleMessage = (messageId) => (event) => {
        var tempGraph = this.state.graph
        var name = event.target.name 
        var value = event.target.value
        var index = this.state.selectedNode

        var tempMessage = tempGraph[index].instruction

        if(name === "role"){
            tempMessage[messageId].role = value
        }
        else if(name==="Default"){
            tempMessage[messageId].messageList[0].check = !tempMessage[messageId].messageList[0].check
        }
        else if(name==="Interrupted"){
            tempMessage[messageId].messageList[1].check = !tempMessage[messageId].messageList[1].check 
        }

        tempGraph[index].instruction = tempMessage

        this.setState((prev)=>({ 
            graph: tempGraph
        }), ()=>{
            JamBuilderUtil.saveGraph(this.state.data._id, this.state.graph)
        })
    }

    /**
     * FUNCTIONS FOR EVENTS
     **/

    //addEvent - to add new events nodes 
    addEvent = (parentId) => {
        var tempGraph = this.state.graph
        var index = this.state.selectedNode
        var tempEvents = this.state.graph[index].events

        //Get the latest id
        var latestId = tempEvents[parentId].id

        for(var i=0; i<tempEvents.length; i++){
            if (tempEvents[i].id > latestId) {
                latestId = tempEvents[i].id
            }
        }
        latestId = latestId + 1

        //Check which which parent event to put the new event under
        if(tempEvents[parentId].nextId!=null){
            for(var j=0; j<tempEvents.length; j++){ // get index
                if(tempEvents[parentId].nextId  === tempEvents[j].id){
                    parentId = j
                }
            }

            if(tempEvents[parentId].nextId!=null){
                for(var j=0; j<tempEvents.length; j++){ // get index
                    if(tempEvents[parentId].nextId  === tempEvents[j].id){
                        parentId = j
                    }
                }
            }
        }

        tempEvents[parentId].nextId = latestId

        var newEvent = {
            id: latestId,
            name: " ",
            success: null,
            fail: null,
            nextId: null,
        }
        
        tempEvents.push(newEvent)

        tempGraph[index].events = tempEvents

        this.setState({
            graph : tempGraph 
        }, ()=>{
            
            JamBuilderUtil.saveGraph(this.state.data._id, this.state.graph)
        })
    }

    //remove event - to delete events
    removeEvent = async (parentId) => {
        // var tempGraph = this.state.graph
        // var index = this.state.selectedNode
        // var tempEvents = this.state.graph[index].events

        // let promise = new Promise((resolve) =>{
        //     tempEvents = this.removeEventUtil(tempEvents[parentId].id, tempEvents)

        //     resolve(tempEvents)
        // })

        // let result = await promise
        // tempGraph[index].events = result

        this.setState(state =>{
            let graph = state.graph
            let selectedNode = state.selectedNode
            let events = state.graph[selectedNode].events

            events = this.removeEventUtil(events[parentId].id, events)

            graph[selectedNode].events = events

            return{
                graph
            }
        }, ()=>{
            JamBuilderUtil.saveGraph(this.state.data._id, this.state.graph)
        })
    }

    //remove event util - to remove child events
    removeEventUtil = (id, tempEvents) => {
        
        let index = null
        let events = tempEvents
        let isDiffBranch = false
        for(let i=0; i<events.length; i++){
            //retrieve event's index
            if (events[i].id === id) {
                index = i
            }

            if(events[i].success === id || events[i].fail === id){
                isDiffBranch = true
            }
        }


        for(let i=0; i<events.length; i++){
            //remove this event from the previous event's nextId
            if (events[i].nextId === id){
                if(events[index].nextId && !isDiffBranch){
                    events[i].nextId = events[index].nextId
                }
                else{
                    events[i].nextId = null
                }
            }
        }

        if (events[index].fail != null){
            events = this.removeEventUtil(events[index].fail, events)
        }
        
        if (events[index].success != null ){
            events = this.removeEventUtil(events[index].success, events)
        }

        // if (events[index].nextId != null ){
        //     events = this.removeEventUtil(events[index].nextId, events)
        // }

        events.splice(index, 1)
        return events
    }

    //handleEventScript - to handle change of event selection
    //nodeId - selected graph node id
    //parentId - index of selected event script
    //eventId - id of selected event script
    handleEventScript = (nodeId, parentId, eventId) => (event) => {
        var tempGraph = this.state.graph
        var index = this.state.selectedNode
        var tempEvents = this.state.graph[index].events
        var action = event.target.value
        var updParent //updated parent event

        //Remove nodes related to parent's success and fail
        var parentSuccess = tempEvents[parentId].success 
        var parentFail = tempEvents[parentId].fail

        for(var i=0; i<tempEvents.length; i++){
            if(tempEvents[i].id===parentSuccess){
                tempEvents.splice(i, 1)
            }
            if(tempEvents[i].id===parentFail){
                tempEvents.splice(i, 1)
            }
        }

        //Create new object for parent
        updParent = {
            id: eventId,
            name: action,
            success: null,
            fail: null,
            nextId: tempEvents[parentId].nextId                
        }

        if(action==="Check Card in Hand"){  
            //Check card in hand
            updParent.role = " "
            updParent.card = " " 
            updParent.operation = " "
            updParent.selection = "Reference"
            updParent.reference = " "
            updParent.number = 0
        }
        // else if(action==="Check Vote Amount"){
        //     //Check vote amount
        //     updParent.card = " " 
        //     updParent.operation = " "
        //     updParent.selection = "Reference"
        //     updParent.reference = " "
        //     updParent.number = 0
        // }
        // else if(action==="Vote on Card"){
        //     //Vote on card
        //     updParent.selection = "All Card"
        //     updParent.reference = " "
        //     updParent.target = " "
        // }
        else if(action==="Add Point"){
            //Add point
            updParent.number = 0
            updParent.reference = " "
        }
        else if(action==="Clear Card"){
            //Clear all
            updParent.selection = "All Card"
            updParent.card = " "
        }
        else if(action==="Draw Card"){
            //Draw card
            updParent.number = 0
            updParent.card = " " 
            updParent.selection = "All Players"
            updParent.reference = " "
            updParent.target = " "
            updParent.filter = " "
        }
        else if(action==="Skip Phase"){
            //Skip phase
            updParent.phase = " "
        }
        else if(action==="Shift To"){
            //Shift To
            updParent.reference = " "
        }
        else if(action==="Vote on Card"){
            //Vote on card
            updParent.selection = "All Card"
            updParent.reference = " "
            updParent.target = " "

            //Check vote amount
            updParent.card = " " 
            updParent.operation = " "
            updParent.cvaselection = "Reference"
            updParent.cvareference = " "
            updParent.number = 0
        }
        else if(action === "Timer"){
            updParent.timerSec = 0
            updParent.timerMin = 0
        }
        
        //If action selected is either Check Card in Hand & Check Vote Amount, add new nodes for parent's success & fail
        if(action==="Check Card in Hand" || action==="Vote on Card"){
            var latestId = tempEvents[0].id
            for(let i=0; i<tempEvents.length; i++){
                if (tempEvents[i].id > latestId){
                    latestId = tempEvents[i].id 
                }
            }

            latestId = latestId + 1
            
            updParent.success = latestId
            updParent.fail = latestId + 1

            var newEvents = [{
                id: latestId,
                name: " ",
                success: null,
                fail: null,
                nextId: null                
            },
            {
                id: latestId + 1,
                name: " ",
                success: null,
                fail: null,
                nextId: null
            }]

            tempEvents = tempEvents.concat(newEvents)
        }

        

        tempEvents[parentId] = updParent

        tempGraph[index].events = tempEvents
        
        this.setState({
            graph: tempGraph 
        }, ()=>{
            JamBuilderUtil.saveGraph(this.state.data._id, this.state.graph)
        })

    }

    //handleEventScriptFields - to handle selections of individual fields 
    handleEventScriptFields = (parentId) => (event) => {
        var name = event.target.name 
        var value = event.target.value
        var tempGraph = this.state.graph
        var index = this.state.selectedNode
        var tempEvents = tempGraph[index].events

        if(name === "role"){
            tempEvents[parentId].role = value
        }
        else if(name === "card"){
            tempEvents[parentId].card = value
        }
        else if(name === "operation"){
            tempEvents[parentId].operation = value
        }
        else if(name === "selection"){
            tempEvents[parentId].selection = value
        }
        else if(name === "reference"){
            tempEvents[parentId].reference = value
        }
        else if(name === "number"){
            tempEvents[parentId].number = value
        }
        else if(name === "target"){
            tempEvents[parentId].target = value
        }
        else if(name === "filter"){
            tempEvents[parentId].filter = value
        }
        else if(name === "phase"){
            tempEvents[parentId].phase = value
        }
        else if(name === "cvaselection"){
            tempEvents[parentId].cvaselection = value
        }
        else if(name === "cvareference"){
            tempEvents[parentId].cvareference = value
        }
        else if(name === "notification"){
            tempEvents[parentId].notification = value
        }
        else if(name === "timer-min"){

            tempEvents[parentId].timerMin = parseInt(value,10)
        }
        else if(name === "timer-sec"){
            tempEvents[parentId].timerSec = parseInt(value,10)
        }
        else if(name === "email"){
            tempEvents[parentId].content = value
        }
        else if(name === "point"){
            tempEvents[parentId].point = value
        }
    
        tempGraph[index].events = tempEvents

        this.setState({
            graph: tempGraph
        }, ()=>{
            JamBuilderUtil.saveGraph(this.state.data._id, this.state.graph)
        })
    }

    changeDeck = (endpoint) => {
        let name_secure = replaceWhiteSpace(this.state.data.createdBy.name.first) // whitespace replacer 
        let userDir = `${this.state.data.createdBy._id}-${name_secure}`

        if(endpoint === "storyDesign"){
            axios.get(`${config.backend.uri}/api/game/getDeck`,{params: {endpoint}}).then((res)=>{
                let deck = res.data.deck
    
                this.setState(prevState =>({
                    data: {
                        _id: prevState.data._id,
                        name: prevState.data.name,
                        createdBy: prevState.data.createdBy,
                        configIndex: prevState.data.configIndex,
                        deckEndpoint: endpoint,
                        deck: deck,
                    },
                    deckAnchorEl: null,
                }))

                this.props.client.mutate({
                    mutation: JAMBUILDER_CHANGE_DECK,
                    variables:{
                        _id: this.state.data._id,
                        deckEndpoint: endpoint,
                        deck:deck
                    }
                }).then((res)=>{
                        console.log(res)
                })
            })

        }else{
            axios.get(`${config.backend.uri}/api/game/getCustomDeck`,{params: {endpoint,userDir}}).then((res)=>{
                let deck = res.data.deck

                this.setState(prevState =>({
                    data: {
                        _id: prevState.data._id,
                        name: prevState.data.name,
                        createdBy: prevState.data.createdBy,
                        configIndex: prevState.data.configIndex,
                        deckEndpoint: endpoint,
                        deck: deck,
                    },
                    deckAnchorEl: null,
                }))

                
                this.props.client.mutate({
                    mutation: JAMBUILDER_CHANGE_DECK,
                    variables:{
                        _id: this.state.data._id,
                        deckEndpoint: endpoint,
                        deck:deck
                    }
                }).then((res)=>{
                        console.log(res)
                })
            })
        }
    }

    /**
     * checkEmptyEvent is to check if there's any event settings that weren't filled
     */
    checkEmptyEvent = () =>{
        const {graph} = this.state
        const actionNodes = graph.filter(function(node) {return node.type === "Action";});
        let emptyState = false

        for(let i=0; i<actionNodes.length; i++){ //go through every action node's event script
            for(let j=0; j<actionNodes[i].events.length; j++){ //go through each event script settings
                let event = actionNodes[i].events[j]
                let eventName = event.name
                // console.log(eventName)
                /* Check if properties in selected event script is not filled.
                * Needed to check necessary property based on selected event script because
                * properties will stay if event script changes
                */
                if(eventName === "Check Card in Hand"){ 
                    if(typeof event.role !== "undefined" && (!event.role || event.role === " ")){
                        emptyState = true
                        break;
                    }
                    if(typeof event.card !== "undefined" && (!event.card || event.card === " ")){
                        emptyState = true
                        break;
                    }
                    if(typeof event.operation !== "undefined" && (!event.operation || event.operation === " ")){
                        emptyState = true
                        break;
                    }
                }
                else if(eventName === "Vote on Card"){
                    if(typeof event.reference !== "undefined" && (!event.reference || event.reference === " ")){
                        emptyState = true
                        break;
                    }
                    if(typeof event.operation !== "undefined" && (!event.operation || event.operation === " ")){
                        emptyState = true
                        break;
                    }
                    if(event.cvaselection === "Reference" && typeof event.cvareference !== "undefined" && (!event.cvareference || event.cvareference === " ")){
                        emptyState = true
                        break;
                    }
                }
                else if (eventName === "Clear Card"){
                    if(event.selection === "Target Card" && event.card === " "){
                        emptyState = true
                        break;
                    }
                }
                else if (eventName === "Draw Card"){
                    if(typeof event.card !== "undefined" && (!event.card || event.card === " ")){
                        emptyState = true
                        break;
                    }
                    if(event.selection === "Target" && typeof event.target !== "undefined" && (!event.target || event.target === " ")){
                        emptyState = true
                        break;
                    }
                }
                else if(eventName === "Shift To"){
                    if(typeof event.reference !== "undefined" && (!event.reference || event.reference === " ")){
                        emptyState = true
                        break;
                    }
                }
            }
            
            if(emptyState){ //if any of the field is empty then true
                break;
            }
        }
        return emptyState
    }
}


export default withApollo(withRouter(withStyles(styles)(JamBuilder)));
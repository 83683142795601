import PlayerState from '../state/playerState'
import CanvasState from '../state/canvasState'
import {EventManager} from './gameCanvasHelper'
import EE from '../../../api/eventemitter'
import { getUserId, isObjEmpty } from '../../../utility/function'

//Playerlist & permissions manipulation for the gameCanvas
export const setRoles = (currentTurnStruct, playerTurn, playerList) =>{
        //This function runs through the list of participants to update the playerList
        //by checking the against the current playerTurn and returns an array to setState later

        for(let index = 0; index < playerList.length; index++){
            if(index === parseInt(playerTurn)){
                let role = CanvasState.gameConfig
                            [currentTurnStruct]
                            ["inTurn"]
                            ["mainRole"]

                playerList[index]["role"] = role
                playerList[index]["turnStatus"] = "inTurn"
                
                
                CanvasState["currentPlayer"]["index"] = index
                CanvasState["currentPlayer"]["name"] = playerList[index]["username"]
                CanvasState["currentPlayer"]["id"] = playerList[index]["uid"]
            }
            else{
                let role = CanvasState.gameConfig
                            [currentTurnStruct]
                            ["outTurn"]
                            ["mainRole"]

                playerList[index]["role"] = role
                playerList[index]["turnStatus"] = "outTurn"
            }
        }
        /*
        playerList will return an array of objs with the structure
        playerList[index]:
        {
            uid: User's id
            username: User's name
            usercolor: User's assigned colour for the session
            role: Storyteller/Pitcher/etc
            turnStatus: In Turn/ Out Turn?
            score: User's score   (In the setScore function)
            currentStep: User's current actions'
            permissions: {objs of true/false they can do} based on the step
        }
        */
        return playerList
}

export const getScore = (loaded, playerList) =>{

    // get score from participation list

    if(!loaded){
        for(let index = 0; index < playerList.length; index++){
            playerList[index]["score"] = playerList[index]["score"] ?? 0
        }
    }

    return playerList
}

export const setScore = (playerList, playerID, newScore) =>{
    
    const i = playerList.findIndex((val)=>val.uid === playerID)

    playerList[i].score = newScore

    return playerList
}

export const setStep = (currentTurnStruct, playerList) =>{
    for(let index = 0; index < playerList.length; index++){

        let player = playerList[index]
        let currentUID = getUserId()

        // if(!playerList[0]["currentStep"] && index === 0){
        //     // run new 
        //     console.log("RUN NEW EVENT")
        //     if(CanvasState.gameConfig
        //         [currentTurnStruct]
        //         [player["turnStatus"]]
        //         [player["role"]]
        //         [player["currentStep"]].events
        //     ){
        //         EventManager.eventPathWay(CanvasState.gameConfig
        //             [currentTurnStruct]
        //             [player["turnStatus"]]
        //             [player["role"]]
        //             [player["currentStep"]].events
        //         )
        //     }else{
        //         console.log("NO EVENT")
        //     }

        // }else{
        //     console.log("NOPE")
        //     let n = CanvasState.gameConfig[currentTurnStruct][player["turnStatus"]][player["role"]][player["currentStep"]].events

        //     if(n){
        //         EE.emit("Step Event",n[n["startingEvent"]])
        //     }
        // }

        playerList[index]["currentStep"] = CanvasState.gameConfig
                                            [currentTurnStruct]
                                            [playerList[index]["turnStatus"]]
                                            [playerList[index]["role"]]
                                            ["startingStep"]

        playerList[index]["permissions"]  = CanvasState.gameConfig
                                            [currentTurnStruct]
                                            [playerList[index]["turnStatus"]]
                                            [playerList[index]["role"]]
                                            [playerList[index]["currentStep"]]

        if((playerList[index].uid === currentUID) && playerList[index].currentStep){ // here have issue

            let n = CanvasState.gameConfig
                [currentTurnStruct][player["turnStatus"]]
                [player["role"]]
                [playerList[index]["currentStep"]].events

            //let se = n.startingEvent
            // console.log(CanvasState.n)
            // console.log(isObjEmpty(n))
            // console.log(n)
            
            if(n && n.hasOwnProperty(n.startingEvent)){
                
                let se = n.startingEvent
                if(!isObjEmpty(n[se])){
                    // console.log(n[se])
                    setTimeout(()=>{EE.emit("Step Event",n)},1000)
                }
            }

        }
    }

    return playerList
}

export const iterateStep = (currentTurnStruct, playerList, playerID, shiftStep) =>{
    for(let i = 0; i < playerList.length; i++){
        let player = playerList[i] 
        
        if(player.uid === playerID){
            player["currentStep"] = shiftStep
            player["permissions"] = CanvasState.gameConfig[currentTurnStruct][player["turnStatus"]][player["role"]][player["currentStep"]]

            if(player.currentStep){
                let n = CanvasState.gameConfig[currentTurnStruct][player["turnStatus"]][player["role"]][playerList[i]["currentStep"]].events
                // console.log(n)
                // if(n){
                //     setTimeout(()=>{EE.emit("Step Event",n)},1000)
                // }

                if(n && n.hasOwnProperty(n.startingEvent)){
                
                    let se = n.startingEvent
                    if(!isObjEmpty(n[se])){
                        // console.log(n[se])
                        setTimeout(()=>{EE.emit("Step Event",n)},1000)
                    }
                }
            }

            break
        }
        
    }
    
    return playerList
}

export const loadStep = (playerPermissions, currentTurnStruct, playerList) => {

    for (let index = 0; index < playerList.length; index++){

        let player = playerList[index]
        let currentUID = getUserId()

        if(playerList[index].currentStep && (playerList[index].uid === currentUID)){ 

            //console.log("RUN NEW EVENT",CanvasState.gameConfig,player)
            let n = CanvasState.gameConfig[currentTurnStruct][player["turnStatus"]][player["role"]][playerPermissions[index]["currentStep"]].events
            if(n && n.hasOwnProperty(n.startingEvent)){
                
                let se = n.startingEvent
                if(!isObjEmpty(n[se])){
                    // console.log(n[se])
                    setTimeout(()=>{EE.emit("Step Event",n)},1000)
                }
            }
        }

        playerList[index]["currentStep"] = playerPermissions[index]["currentStep"]
        playerList[index]["score"] = playerPermissions[index]["score"]

        playerList[index]["permissions"] = CanvasState.gameConfig
                                            [currentTurnStruct]
                                            [playerList[index]["turnStatus"]]
                                            [playerList[index]["role"]]
                                            [playerList[index]["currentStep"]]
    }

    return playerList
}

export const setCurrentPermissions = (currentTurnStruct, playerList, currentUserID) =>{
    for(let index = 0; index < playerList.length; index++){
        playerList[index]["permissions"]  = CanvasState.gameConfig
                                                [currentTurnStruct]
                                                [playerList[index]["turnStatus"]]
                                                [playerList[index]["role"]]
                                                [playerList[index]["currentStep"]]  
    }

    let result = playerList.filter(player => player.uid === currentUserID)
    
    return result[0]["permissions"]
}

export const returnPermissions = (currentUserID, playerList) =>{
    //Filters the corresponding player to generate their permission state
    let result = playerList.filter(player => player.uid === currentUserID)
    let player = result[0]

    if(player){
        // CanvasState.playerList = playerList
        PlayerState.role = player["role"]
        return player["permissions"]
    }else{
        // spectator hard config
        let sp = {
            cardAddition:{
                "Blank": {canvas: true, inHand: true},
            },
            cardConfig:{
                draggable: ['Blank'],
                edittable: true,
                flippable: [],
                pairable: ['Blank'],
                pinnable: [],
                removable: ['Blank'],
                retrieveable: ['Blank'],
                stackable: [],
            },
            actions: {},
        }

        return sp;
    }
}

// export const setListOfPins = (playerList) =>{
//     for(let index = 0; index < playerList.length; index++){
//         playerList[index]["permissions"] = [] 
//     }

//     return playerList
// }

// export const loadListOfPins = (playerList) =>{
//     for(let index = 0; index < playerList.length; index++){
//         playerList[index]["permissions"] = playerPermissions[index]['listOfPins']
//     }

//     return playerList
// }
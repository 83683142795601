export const styles = {
    //Container 
    playerInfoHolder:{
        position:"absolute",
        width:"100%", 
        bottom:"0", 
        left:"0",
        pointerEvents: "none",
        zIndex:"4"
    },


    playerInfoWrapper:{
        position:"relative", 
        margin:"auto", 
        width:"fit-content", 
        pointerEvents: "auto"
    },

    //Player avatars
    avatarWrapper:{
        position:"relative", 
        padding:"2px", 
        zIndex:"1", 
        background:"#F2F2F2", 
        border:"0.5px solid #C4C4C4", 
        borderRadius:"100px"
    },

    avatar:{
        width:"50px",
        height:"50px",
        background:"#E97610",
    },

    
    score:{
        position:"absolute", 
        overflow:"hidden",
        width:"25px",
        height:"25px", 
        lineHeight: "25px", 
        verticalAlign:"0", 
        textAlign:"center", 
        top:"5px", 
        right:"5px", 
        zIndex:"2", 
        borderRadius:"10px", 
        boxShadow:"0px 0px 2px rgba(0,0,0, 0.2)",
        color:"#4F4F4F", 
        background:"white"
    },

    input: {
        '&.avatar-score-nodrag': {  // Target the existing className using the class selector
          '&:hover': {
            backgroundColor: 'yellow',  // Change background to yellow on hover
          },
          transition: 'background-color 0.3s',  // Optional smooth transition
        },
    },

    status:{
        position:"absolute", 
        // padding:"4px 5px", 
        padding:"3px",
        cursor: "pointer",
        top:"50px", 
        right:"15px", 
        zIndex:"2", 
        borderRadius:"100px", 
        background:"white",
        border:"1px solid #c4c4c4"
    },

    statusTyping:{
        // padding:"4px 5px", 
        padding:"3px",
        cursor: "pointer",
        borderRadius:"100px", 
        background:"#2CB67D",
    },

    roleIcon:{
        fontSize:"15px",
    },

    info:{
        position:"absolute",   
        left:"-25px", 
        padding:"0 5px", 
        fontSize:"12px",
        borderRadius:"5px", 
        background:"#C4C4C4", 
        width:"50px",
        color:"white"
    },

    name:{
        fontFamily:"Montserrat", 
        marginTop:"10px", 
        marginBottom:"-10px",
        fontWeight:"bold", 
        textAlign:"center", 
        textTransform:"uppercase",
        color:"#4F4F4F"
    },

    arrow:{
        display:"none",
        marginLeft:"2px", 
        fontSize:"40px", 
        marginTop:"-28px",
        position:"absolute", 
        color:"#FFAA0D",
        
    },


    active:{
        background:"#FFAA0D"
    },

    avatarBg:{
        padding:"20px",
        borderRadius:"5px",
        marginBottom:"10px"
    },

    activeBg:{
        background:"#E0E0E0", 
    },

    //Pass button
    actionBtnWrapper:{
        display:"inline-block", 
        borderLeft:"1px solid #c4c4c4", 
        height:"50px", 
        padding:"12px 20px 0 20px",
    },

    passBtn:{
        fontFamily:"arial",
        marginBottom:"100px", 
        background:"#4173D6", 
        padding:"0.2rem 3.3rem 0.2rem 3.3rem",
        color:"white",
        pointerEvents: "auto",
        '&:focus':{
            outline:"none"
        },
        '&:disabled':{
            background:"#c4c4c4",
            color:"#fff"
        }
    },

    menuItemHover:{
        '&:hover': {
            background: "#F5EFD2 !important"
        },
        paddingTop: "0.2rem",
        paddingBottom:"0.2rem",
        userSelect:"none",
        cursor: "pointer",
        border:"0.1px solid black"
    },

    menuItemText:{
        '& span, & svg': {
            fontSize: '0.7rem'
        },
        paddingLeft:'0.4rem'
    },

    menuIcon:{
        width: "1rem",
        height: "1rem",
    },

    //Prompt
    botAvatarWrapper:{
        position:"absolute", 
        padding:"2px", 
        marginTop:"-18px", 
        marginLeft:"-58px",
        background:"#F2F2F2", 
        border:"0.5px solid #C4C4C4", 
        borderRadius:"100px",
        pointerEvents: "auto"
    },

    botAvatar:{
        width:"50px", 
        height:"50px"
    },

    passIcon:{
        marginLeft:"15px", 
        borderLeft:"0.5px solid white"
    },

    promptWrapper:{
        position:"relative", 
        width:"100%", 
        marginBottom:"20px", 
        transition:"0.5s",
        pointerEvents: "auto"
    },

    prompt:{
        display:"block", 
        width:"fit-content",
        margin:"auto",
        top:"-50px",
        padding:"5px 15px", 
        fontFamily:"Montserrat", 
        fontSize:"13px",
        background:"#DFEEF1",
        textAlign:"center", 
        borderRadius:"5px", 
        color:"#4F4F4F",
    },


    //Player Card Info
    playerInfo:{
        opacity:"0",
        fontSize:"8px", 
        textAlign:"center", 
        width:"100px", 
        height:"140px", 
        padding:"10px", 
        margin:"-120px 0 0 -3px", 
        background:"#fff", 
        borderRadius:"5px", 
        position:"absolute", 
        boxShadow:"4px 4px 4px rgba(0,0,0,0.1)",
        pointerEvents: "auto",
        transition:"0.5s",
        '&:hover':{
            transform: "scale(1.5)",
            marginTop:"-150px", 
            zIndex:"1",
           
        }
    },

    closeIcon:{
        position:"absolute", 
        top:"10px", 
        right:"10px", 
        fontSize:"10px", 
        color:"#C4C4C4"
    },

    cardRoleBg:{
        margin:"auto", 
        width:"25px", 
        height:"25px"
    },

    cardRole:{
        fontSize:"10px",
        color:"#4F4F4F"
    },

    cardName:{
        fontSize:"8px", 
        color:"#C4C4C4", 
        marginTop:"-5px"
    },

    cardPermission:{
        marginTop:"-10px", 
        textAlign:"left"
    },

    cardPermissionList:{
        fontSize:"8px", 
    },

    cardCardNoHolder:{
        position:"absolute", 
        bottom:"5px", 
        right:"10px"
    },

    cardCard1:{
        fontSize:"12px", 
        marginTop:"-5px", 
        color:"#c4c4c4", 
        background:"#fff"
    },

    cardCard2:{
        fontSize:"12px", 
        marginLeft:"-15px", 
        marginTop:"-2px", 
        background:"#fff", 
        color:"#c4c4c4", 
        transform: "rotate(-30deg)"
    },

    cardCardNo:{
        marginLeft:"5px", 
        fontFamily:"Montserrat", 
        color:"#c4c4c4", 
        fontSize:"10px"
    },

    fade:{
        color:"#D1D1D1"
    }
}
import gql from 'graphql-tag'


const fullQuery = `
        index
    	_id
        title
        description
        participants{
          _id
          name{
            first
          }
        }
        parentFruit
        seed
        status
        jamtype
        ideabank{
          idea
          ideaBy
          ideaByName
          fromPhase
          generatedOn
          iteration{
            idea
            ideaBy
            ideaByName
            fromChatId
          }
        }
        createdBy{
          _id
          name{
            first
          }
        }
        phases{
          index
          name
          instruction
          field
        }
        transcript{
          chats{
            owner
            ownerName
            msg
          }
        }
    	results

`

export const JAMBUILDER_CHANGE_DECK = gql `

        mutation JAMBUILDER_CHANGE_DECK($_id: MongoID!, $deckEndpoint: String, $deck : JSON){
            jamBuilderUpdateById(record:{_id: $_id, deckEndpoint: $deckEndpoint, deck: $deck}){
                record{
                    _id
                    deckEndpoint
                    deck
                }
            }
        }
`

export const JAMBUILDER_INTERPRETER = gql`

        mutation JAMBUILDER_INTERPRETER($_id: MongoID, $graph: JSON, $graphName: String, $creator: JSON, $newIndex: String, $general: JSON){
            jamBuilderInterpreter(_id: $_id, graph: $graph, graphName: $graphName, creator: $creator, newIndex: $newIndex, general: $general){
                _id
                graphName
                interpreterMessage
            }
        }
`;

export const GAME_JAMBUILDER_SETUP = gql `

        mutation GAME_JAMBUILDER_SETUP($endpoint: String, $gameLink: MongoID, $builderID: String){
            gameJambuilderSetup(endpoint: $endpoint, gameLink: $gameLink, builderID: $builderID){
                _id
            }
        }

`

export const DUPLICATE_GAME = gql`
        mutation DUPLICATE_GAME($_id: MongoID, $setup: JSON){
            gameDuplicate(_id: $_id, setup: $setup){
                _id
            }
        }
`

export const CREATE_JAMBUILDER = gql`

        mutation CREATE_JAMBUILDER($name: String, $createdBy: MongoID, $configIndex: String, $deckEndpoint: String, $templateType: String, $jamBuilderGraph: JSON, $general: JSON, $deck: JSON){
            jamBuilderCreateOne(name: $name, createdBy: $createdBy, configIndex: $configIndex, deckEndpoint: $deckEndpoint, templateType: $templateType, jamBuilderGraph: $jamBuilderGraph, general: $general, deck: $deck){
                _id
                name
                configIndex
                deckEndpoint
            }
        }
`;

export const GAME_UPDATE_SPECTATORMODE = gql`
    mutation GAME_UPDATE_SPECTATORMODE($_id: MongoID, $spectatorMode: Boolean){
        gameUpdateSpectatorMode(_id: $_id, spectatorMode: $spectatorMode){
            _id
        }
    }
`

export const GAME_UPDATE_TRANSCRIPT = gql`

        mutation UPDATE_GAME_TRANSCRIPT($_id: MongoID, $st: JSON){
            gameUpdateTranscript(_id: $_id, st: $st){
                _id
                transcript
            }
        }

`

export const UPDATE_JAMBUILDER_IMAGE = gql`
        mutation UPDATE_JAMBUILDER_IMAGE($_id: MongoID, $imageUrl: String){
            jamBuilderUpdateImage(_id: $_id, imageUrl: $imageUrl){
                _id
                image
            }
        }


`

export const UPDATE_JAMBUILDER = gql`

        mutation UPDATE_JAMBUILDER($_id: MongoID!, $graph: JSON){
            jamBuilderUpdateById(record:{_id: $_id, graph: $graph}){
                record{
                    _id
                    graph
                }
            }
        }
`;

export const UPDATE_JAMBUILDER_NAME = gql`
        mutation UPDATE_JAMBUILDER_NAME($_id: MongoID, $graphName: String){
            jamBuilderUpdateName(_id: $_id, graphName: $graphName){
                _id
            }
        }
`

export const UPDATE_JAMBUILDER_GENERAL = gql`
    mutation UPDATE_JAMBUILDER_GENERAL($_id: MongoID, $general: JSON){
        jamBuilderUpdateGeneral(_id: $_id, general: $general){
            _id
        }
    }
`

export const GAME_UPDATE_RULEBOOK = gql`
    mutation GAME_UPDATE_RULEBOOK($_id: MongoID, $rulebook: String){
        gameUpdateRulebook(_id: $_id, rulebook: $rulebook){
            _id
        }
    }
`


export const GAME_UPDATE_DECK = gql`

        mutation GAME_UPDATE_DECK($_id: MongoID, $deck: JSON){
            gameUpdateDeck(_id: $_id, deck: $deck){
                deck
            }
        }

`;

export const GAME_UPDATE_OBJECT_FLIP = gql`

        mutation GAME_UPDATE_OBJECT_FLIP($_id: MongoID, $objId: String, $flip: Boolean){
            gameUpdateObjectFlip(_id: $_id, objId: $objId, flip: $flip ){
                data
            }
        }

`;

export const GAME_UPDATE_OBJECT = gql`
        mutation GAME_UPDATE_OBJECT($_id: MongoID, $obj: JSON, $objId: String){
            gameUpdateObject(_id: $_id, obj: $obj, objId: $objId){
                _id
            }
        }

`

export const GAME_UPDATE_OBJECTS = gql`
    mutation GAME_UPDATE_OBJECTS($_id: MongoID, $objs: JSON){
        gameUpdateObjects(_id: $_id, objs: $objs){
            _id
        }
    }
`;

export const GAME_UPDATE_CANVASDATA = gql`
    mutation GAME_UPDATE_CANVASDATA($_id: MongoID, $canvasData:JSON){
        gameUpdateCanvasData(_id: $_id, canvasData: $canvasData){
            _id
        }
    }
`

export const GAME_OBJECT_POSITION = gql`

    mutation GAME_OBJECT_POSITION($_id: MongoID, $objId: String, $x: Float, $y: Float){
        gameUpdateObjectPosition(_id: $_id, objId: $objId, x: $x , y: $y){
            _id
            data
        }
    }

`

export const GAME_OBJECT_CONTENT = gql`

    mutation GAME_OBJECT_CONTENT($_id: MongoID, $objId: String, $content: String, $submitted: Boolean){
        gameUpdateObjectContent(_id: $_id, objId: $objId, content: $content, submitted: $submitted ){
            _id
            data
        }
    }

`

export const GAME_UPDATE_LABEL_AND_CATEGORY = gql`
    mutation GAME_UPDATE_LABEL_AND_CATEGORY($_id: MongoID, $listOfLabels: JSON, $listOfCategories: JSON){
        gameUpdateLabelAndCategory(_id: $_id, listOfLabels: $listOfLabels, listOfCategories: $listOfCategories){
            _id
        }
    }
`

export const GAME_UPDATE_CATEGORYCOLOR = gql`
    mutation GAME_UPDATE_CATEGORYCOLOR($_id: MongoID, $categoryColorMap: JSON){
        gameUpdateCategoryColor(_id: $_id, categoryColorMap: $categoryColorMap){
            _id
        }
    }
`

export const USER_UPDATE_BY_ID = gql`
    mutation UserUpdateById($_id: MongoID!){
        userUpdateById(record:{_id: $_id}){
            record{
                _id
            }
        }
    } 
`
export const USER_UPDATE_DECK = gql`
    mutation UserUpdateDeck($_id: MongoID, $deckID: String, $deckData: JSON){
        userUpdateDeck(_id: $_id, deckID: $deckID, deckData: $deckData){
            _id
        }
    }
`

export const USER_UPDATE_DECK_NAME = gql`
    mutation UserUpdateDeckName($_id: MongoID, $deckName: String, $deckID: String){
        userUpdateDeckName(_id: $_id, deckName: $deckName, deckID: $deckID){
            _id
        }
    }
`

export const USER_UPDATE_DECK_CARDS = gql`
    mutation UserUpdateDeckCards($_id: MongoID, $deckID: String, $deckDump: JSON, $categoryColorMap: JSON, $listOfCategories: JSON, $listOfLabels: JSON){
        userUpdateDeckCards(_id: $_id, deckDump: $deckDump, deckID: $deckID, categoryColorMap : $categoryColorMap, listOfCategories : $listOfCategories, listOfLabels: $listOfLabels){
            _id
        }
    }
`

export const USER_CREATE_DECK_YAML = gql`
    mutation createDeckYAML($_id: MongoID, $name: String, $deckName: String, $deckID: String, $deckDump: JSON, $categoryColorMap: JSON){
        userCreateDeckYAML(_id: $_id, name: $name, deckName: $deckName, deckID: $deckID, deckDump: $deckDump, categoryColorMap: $categoryColorMap){
            _id
        }
    }

`

export const USER_REMOVE_DECK = gql`
    mutation userRemoveDeck($_id: MongoID, $name: String $deckID: String){
        userRemoveDeck(_id: $_id, name: $name, deckID: $deckID){
            _id
        }
    }
`

export const USER_UPDATE_ACTIVITY = gql`
    mutation UserUpdateActivity($_id: MongoID!, $_aid: MongoID!, $date: Date, $type: String){
        userUpdateActivity(_id: $_id, _aid: $_aid, date: $date, type: $type){
            _id
        }
    }
`

export const USER_UPDATE_PASSWORD_BY_ID = gql`
    mutation UserUpdatePasswordById($_id: MongoID!, $password: String){
        userUpdatePasswordById(_id: $_id, password: $password){
            _id
            name{
                first
            }
        }
    }



`

export const CREATE_USER = gql`
    mutation CreateUser($name: String, $email: String, $password: String){
        userCreateOne(name: $name, email: $email, password: $password){
            _id
            name{
                first
            }
            verification
            email
        }
    }
`

export const GAME_DECK_UPDATE = gql`

    mutation GameDeckUpdateById($_id : MongoID!, $deck: JSON){
        gameUpdateById(record:{_id: $_id, deck: $deck}){
            record{
                _id
                name
                deck
            }
        }
    }


`

export const GAME_UPDATE_BY_ID = gql`

    mutation GameUpdateById($_id : MongoID!, $data: JSON){
        gameUpdateById(record:{_id: $_id, data: $data}){
            record{
                _id
                name
                data
            }
        }

    }
`;

export const GAME_UPDATE_PARTICIPANTS = gql`
    mutation GameUpdateById($_id : MongoID!, $participants: JSON){
        gameUpdateById(record:{_id: $_id, participants: $participants}){
            record{
                _id
                name
                participants
            }
        }

    }
`


export const GAME_UPDATE_TIMER = gql`
    mutation GameUpdateTimer($_id : MongoID!, $timer: JSON){
        gameUpdateTimer(_id: $_id, timer: $timer){
            _id
        }
    }
`;

export const GAME_STATUS_UPDATE_BY_ID = gql`

mutation GameStatusUpdateById($_id : MongoID!, $status: String, $participants: JSON, $inhand: JSON){
    gameUpdateStatus(_id: $_id, status: $status, participants: $participants, inhand: $inhand){
            _id
            name
            data
            status
            participants
            inhand
            deck
    }

}
`;

export const GAME_GD_UPDATE_BY_ID = gql`

    mutation GameGDUpdateById($_id : MongoID!, $gameData: JSON){
        gameUpdateGD(_id: $_id, gameData: $gameData){
            _id
            gameData
        }
    }

`;

export const GAME_UPDATE_INHAND = gql`

mutation GameUpdateInHand($_id: MongoID!, $inhand: JSON){
    gameUpdateById(record:{_id: $_id, inhand: $inhand}){
        record{
            inhand
        }
    }
}

`;

export const GAME_UPDATE_PLAYER_HAND = gql`

mutation GameUpdatePlayerHand($_id: MongoID!, $uid: String, $hand: JSON){
        gameUpdatePlayerHand(_id: $_id, uid: $uid, hand: $hand){
            _id
            name
            inhand
        }
    }


`;

export const GAME_UPDATE_LATEST_OBJECT_ID = gql`

mutation GameUpdateLatestObjectId($_id : MongoID!, $objId: String){
    gameUpdateLatestObjectId(_id: $_id, objId: $objId){
        _id
        name
        data
    }

}
`;

export const GAME_UPDATE_TPP = gql`
    mutation GameUpdateTPP($_id: MongoID!, $tppData: JSON){
        gameUpdateTPP(_id: $_id, tppData: $tppData){
            _id
        }
    }
`

export const GAME_UPDATE_LIBRARYOBJECTS = gql`
    mutation GAME_UPDATE_LIBRARYOBJECTS($_id: MongoID!, $libraryObjects: JSON){
        gameUpdateLibraryObjects(_id: $_id, libraryObjects: $libraryObjects){
            _id
        }
    }
`


export const CREATE_GAME = gql`


mutation createGame($name: String, $createdBy: MongoID, $data: JSON, $automation: String, $password: String, $setup: Boolean, $build: Boolean){
    gameCreateOne(name: $name, createdBy: $createdBy, data: $data, automation: $automation, password: $password, setup: $setup, build: $build){
        _id
        name
        automation
        password
        protected
    }
}

`;

export const UPDATE_SESSION = gql`

mutation UpdateSession($_id: MongoID!, $title: String){
    jamsessionUpdateById(record:{_id: $_id, title: $title}){
        record{
            title
        }
    }
}   
`;

export const CREATE_JAM_SESSION = gql`


mutation CreateJamSession($index: String, $createdBy: MongoID!, $title: String, $description: String, $parentFruit: JSON, $jamtype: String, $requirement: String ){
    jamsessionCreateOne(index: $index, createdBy: $createdBy, title: $title, description: $description, parentFruit: $parentFruit, jamtype: $jamtype , requirement: $requirement){

        ${fullQuery}
        
    }
}

`;



export const UPDATE_TRANSCRIPT_BY_ID = gql`

mutation UpdateTranscript($_id: MongoID!, $owner: MongoID!, $ownerName: String, $msg: String, $pinned: Boolean, $cid: String, $quote: JSON){
    updateTranscriptBySession(_id: $_id, owner: $owner, ownerName: $ownerName, msg: $msg, pinned: $pinned, cid: $cid, quote: $quote){
        title
        transcript{
            chats{
                owner
                ownerName
                msg
                pinned
                cid
            }
        }
    }
} 

`;

export const UPDATE_TRANSCRIPT_DISCUSSION_BY_ID = gql`

mutation UpdateTrasncriptDiscussion($_id: MongoID!, $owner: MongoID!, $ownerName: String, $msg: String, $pinned: Boolean, $cid: String, $quote: JSON){
    updateTranscriptDiscussionBySession(_id: $_id, owner: $owner, ownerName: $ownerName, msg: $msg, pinned: $pinned, cid: $cid, quote: $quote){
        title
        transcript{
            discussion{
                owner
                ownerName
                msg
                pinned
                cid
            }
        }
        
    }
} 

`;

export const UPDATE_PARTICIPANTS_BY_ID = gql`

mutation UpdateParticipants($_id: MongoID!, $pid: MongoID!){
    updateParticipantsBySession(_id: $_id, pid: $pid){
        _id
        title
        participants{
            _id
            name{
                first
            }
        }
        createdBy{
            _id
            name{
                first
            }
        }
    }
}

`;


export const UPDATE_PHASE_BY_ID = gql`

mutation UpdatePhases($_id: MongoID!, $index: Int, $phase: String, $instruction: String, $field: JSON){
    updatePhasesBySession(_id: $_id, index: $index, phase: $phase, instruction: $instruction, field: $field){

        _id
        title
        phases{
            index
            name
            instruction
            field
        }
    }

}

`;

export const UPDATE_IDEABANK_BY_ID = gql`

mutation UpdateIdeaBank($_id: MongoID!, $idea: String, $ideaBy: MongoID, $ideaByName: String, $inspiredBy: MongoID, $inspiredByName: String, $fromPhase: Int, $fromChatId: String){
    updateIdeaBankBySession(_id: $_id, idea: $idea, ideaBy: $ideaBy, ideaByName: $ideaByName, inspiredBy: $inspiredBy, inspiredByName: $inspiredByName,  fromPhase: $fromPhase, fromChatId: $fromChatId){

        _id
        title
        ideabank{
            _id
            idea
            ideaBy
            ideaByName
            inspiredBy
            inspiredByName
            fromPhase
            fromChatId
            iteration{
                idea
                ideaBy
                ideaByName
                fromChatId
            }
        }
    }



}

`;

export const UPDATE_PHASE_FIELD_BY_ID = gql`

mutation UpdatePhaseField($_id: MongoID!, $index: Int, $fieldData: JSON ){
    updatePhasesFieldBySession(_id: $_id, index: $index, fieldData: $fieldData){
        _id
        phases{
            index
            field
        }
        
    }
}

`;

// export const UPDATE_NOMINATED_PHASE_FIELD_BY_ID = gql`

// mutation UpdateNominatedPhaseField($_id: MongoID!, $index: Int, $fieldData: JSON){
//     updateNominatedPhaseFieldBySession(_id: $_id, index: $index, fieldData: $fieldData){
//         _id
//         phases{
//             index
//             field
//         }
//     }
// }


// `;

export const UPDATE_NOMINATED_PHASE_FIELD_BY_ID = gql`

mutation UpdateNominatedPhaseField($_id: MongoID!, $index: Int, $fieldData: JSON){
    updateNominatedPhaseFieldBySession(_id: $_id, index: $index, fieldData: $fieldData){
        _id
        index
        field
    }
}


`;




export const UPDATE_NOMINATED_PHASE_FIELD_RESULT_BY_ID = gql`

mutation UpdateNominatedPhaseFieldResult($_id: MongoID!, $index: Int, $fieldData: JSON){
    jamsessionUpdateById(record:{_id: $_id, index: $index, field:{result:{fieldData: $fieldData}}}){
        record{
            _id
            phases{
                index
                field
            }
        }
    }
}

`;

export const UPDATE_CONCLUDE_PHASE_FIELD_BY_ID = gql`

mutation UpdateConcludePhaseField($_id: MongoID, $index: Int, $fieldData: JSON){
    updateConcludePhaseFieldBySession(_id: $_id, index: $index, fieldData: $fieldData){
        _id
        index
        field
    }
}

`;


export const UPDATE_RESULT_BY_ID = gql`

mutation UpdateResult($_id : MongoID!, $results: JSON){
    jamsessionUpdateById(record:{_id: $_id, results: $results, status: "ended"}){
        record{
            ${fullQuery}
        }
    }

}


`;



export const REMOVE_IDEA_BY_ID = gql`

mutation RemoveIdea($_id : MongoID!, $ideaId: MongoID, $fromChatId: String){
    removeIdeaBySession(_id: $_id, ideaId: $ideaId, fromChatId: $fromChatId){
        _id
        ideabank{
            _id
            idea
            ideaBy
            ideaByName
            inspiredBy
            inspiredByName
            fromPhase

        }
    }
}
`;


export const UPDATE_JAM_SESSION_STATUS = gql`

mutation UpdateJamSessionStatus($_id: MongoID!, $status: String){

    jamsessionUpdateById(record: {_id: $_id, status: $status}){
        record{
            ${fullQuery}
        }
    }

}

`;


export const CREATE_PROJECT = gql`

mutation CreateProject($index: String, $createdBy: MongoID, $title: String, $description: String, $workshop: JSON  ){

    projectCreateOne(index: $index, createdBy: $createdBy, title: $title, description: $description, workshop: $workshop){
        _id
        index
        createdBy{
            name{
                first
            }
        }
        title
        description
    }

}
`;


export const PROJECT_UPDATE_JAM = gql`

mutation UpdateJam($_id: MongoID, $jamId: MongoID){

    projectUpdateJam(_id: $_id, jamId: $jamId){
        _id
        index
        jam {
            _id
            title
        }
    }
}
`;


export const JAM_SESSION_UPDATE_SEED = gql`

    mutation UpdateSeed($_id: MongoID!, $seed: String){
        jamsessionUpdateById(record: {_id: $_id, seed: $seed}){
            record{
                ${fullQuery}
            }
        }
    }

`;


export const REMOVE_CONCLUDE = gql`

    mutation RemoveConclude($_id: MongoID, $index: Int, $fieldData: JSON){
        removeConcludeBySession(_id: $_id index: $index, fieldData: $fieldData){
            _id
            field
        }

    }
`;


export const EDIT_COMMENT = gql`

    mutation EditComment($_id: MongoID, $cid: String, $pinned: Boolean , $comment: String, $history: JSON){
        editCommentBySession(_id: $_id, cid: $cid, pinned: $pinned, comment: $comment, history: $history){
            _id
        }
    }

`;

export const ITERATE_IDEA = gql`

    mutation IterateIdea($_id: MongoID, $fromChatId: String, $iteration: JSON){
        iterateIdeaBySession(_id: $_id, fromChatId: $fromChatId, iteration: $iteration){
            _id
        }
    }


`;



export const ADD_PHASE_UPDATE_JAM = gql`

    mutation AddPhaseUpdateJam($_id: MongoID, $index: Int, $name: String, $instruction: String, $settings: JSON, $field: JSON){

        addPhaseUpdateJamBySession(_id: $_id, index: $index, name: $name, instruction: $instruction, settings: $settings, field: $field){

            _id
            phases{
                index
                instruction
                field
            }

        }
    }


`;


export const CANVAS_ADD_OBJECT = gql`

    mutation CanvasAddObject($_id: MongoID, $index: Int, $object: JSON){

        updateCanvasObjectBySession(_id: $_id, index: $index, object: $object){

            field

        }


    }

`;


export const CANVAS_REMOVE_OBJECT = gql`

    mutation CanvasRemoveObject($_id: MongoID, $index: Int, $objId: String){
        removeCanvasObjectBySession(_id: $_id, index: $index, objId: $objId){

            field
        }
    }

`;


export const CANVAS_ADD_RELATION = gql`

    mutation CanvasAddRelation($_id: MongoID, $index: Int, $relation: JSON){
        updateCanvasRelationBySession(_id: $_id, index: $index, relation: $relation){
            field
        }
    }


`;


export const CANVAS_REMOVE_RELATION = gql`
    
    mutation CanvasRemoveRelation($_id: MongoID, $index: Int, $relationId: String){
        removeCanvasRelationBySession(_id: $_id, index: $index, relationId: $relationId){
            field
        }
    }

`;


export const CANVAS_MOVE_OBJECT = gql`

    mutation CanvasMoveObject($_id: MongoID, $index: Int, $objId: String, $top: Int, $left: Int){
        moveCanvasObjectBySession(_id: $_id, index: $index, objId: $objId, top: $top, left: $left){
            field
        }
    }


`;

export const TESTING = gql`

    mutation testing($test: String){
        testing(test: $test){
            _id
        }
    }
`;

export const GAME_EMAIL = gql`

    mutation GameSendEmail($sender: JSON, $recipients: JSON, $title: String, $jamlink: String, $content: String){
        gameSendEmail(sender: $sender, recipients: $recipients, title: $title, jamlink: $jamlink, content: $content){
            _id
        }
    }

`

export const GAME_CLEAR_PLAYER_UNRECEIVED = gql`

    mutation GameClearPlayerUnreceived($gid: String, $uid: String){
        gameClearPlayerUnreceived(gid: $gid, uid:$uid){
            _id
        }
    }

`

export const GAME_UPDATE_NOTIFICATION_LOG = gql `

    mutation GameUpdateNotificationLog($gid: String, $uid: String, $notification: JSON){
        gameUpdateNotificationLog(gid: $gid, uid: $uid, notification: $notification){
            _id
        
        }
    }

`

export const GAME_UPDATE_PLAYER_SCORE = gql `

    mutation GameUpdatePlayerScore($gid: String, $uid: String, $score: Int){
        gameUpdatePlayerScore(gid: $gid, uid: $uid, score: $score){
            _id
        
        }
    }

`

/*
mutation {
    jamsessionUpdateById(record:{
    _id: "5d26fa553ec625083aa1d502",
    title: "hello'"
}){
    record{
        title
    }
}
} */